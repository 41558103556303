// @flow

import { takeLatest, put, call, select } from 'redux-saga/effects';
import type { Saga } from 'redux-saga';
import type { GeneratorType } from 'sagas/root';
import type { ApiExecutorType } from 'types/ApiExecutorType';
import StructureApi from 'api/structure/StructureApi';
import { displayModal } from 'actions/modalActions';
import {
  FETCH_STRUCTURE_START,
  POST_CONFIG_START,
  POST_ACTION_START,
  GET_HIT_FOR_GTM,
  getHitForGtmSuccess,
  structureFetched,
  structureFetchError,
  postStructureConfigSuccess,
  postStructureConfigError,
  postStructureActionSuccess,
  postStructureActionError,
} from 'actions/structureActions';
import { forgotMagicLink } from 'actions/magicLinkActions';
import type { 
  FETCH_STRUCTURE_START_ACTION, 
  POST_CONFIG_START_ACTION, 
  POST_ACTION_START_ACTION, 
  GET_HIT_FOR_GTM_ACTION 
  } from 'actions/structureActions';

const content = {
  title: 'Votre code a expiré'
};

export default function(onBoardingApiExecutor: ApiExecutorType) {
  const structureApi = new StructureApi(onBoardingApiExecutor);
  const getMagicLinkInState = state => state.magicLinkState;


  return function* structureSaga(): GeneratorType {
    yield takeLatest(FETCH_STRUCTURE_START, fetchStructure);
    yield takeLatest(POST_CONFIG_START, postStructureConfig);
    yield takeLatest(POST_ACTION_START, postStructureAction);
    yield takeLatest(GET_HIT_FOR_GTM, getHitForGtm);
  };

  function* fetchStructure(action: FETCH_STRUCTURE_START_ACTION): Saga<void> {
    const { magicLink } = yield select(getMagicLinkInState);

    try {
      const { id } = action.payload;
      const {
        code,
        embleme,
        nom,
        configuration,
        email,
        site_status,
        url_front,
        url_back,
        emailOfficiel,
        string_blacklist,
        domaines_blacklist,
      } = yield call(structureApi.getStructure, id, magicLink);
      yield put(structureFetched({
        code,
        embleme,
        nom,
        configuration,
        email,
        site_status,
        url_front,
        url_back,
        emailOfficiel,
        string_blacklist,
        domaines_blacklist
      }));
    } catch (e) {
      if (e.response.data.code === 'rest_invalid_magic_code') {
        yield put(displayModal(2, content));
      }
      yield put(forgotMagicLink());
      yield put(structureFetchError());
    }
  }

  function* postStructureConfig(action: POST_CONFIG_START_ACTION): Saga<void> {
    const { magicLink } = yield select(getMagicLinkInState);

    try {
      const { id, config } = action.payload;
      const {
        code,
        embleme,
        nom,
        configuration,
        email,
        site_status,
        url_front,
        url_back,
        emailOfficiel,
        string_blacklist,
        domaines_blacklist,
      } = yield call(structureApi.postStructureConfig, id, config, magicLink);
      yield put(postStructureConfigSuccess({
        code,
        embleme,
        nom,
        configuration,
        email,
        site_status,
        url_front,
        url_back,
        emailOfficiel,
        string_blacklist,
        domaines_blacklist
      }));
    } catch (e) {
      if (e.response.data.code === 'rest_invalid_magic_code' && window.location.pathname === '/') {
        yield put(displayModal(2, content));
      }
      yield put(forgotMagicLink());
      yield put(postStructureConfigError());
    }
  }

  function* postStructureAction(action: POST_ACTION_START_ACTION): Saga<void> {
    const { magicLink } = yield select(getMagicLinkInState);

    try {
      const { id, action: actionToSend, email: emailToSend, config } = action.payload;
      const {
        code,
        embleme,
        nom,
        configuration,
        email,
        site_status,
        url_front,
        url_back,
        emailOfficiel,
        string_blacklist,
        domaines_blacklist,
      } = yield call(structureApi.postStructureAction, id, config, actionToSend, emailToSend, magicLink);
      yield put(postStructureActionSuccess({
        code,
        embleme,
        nom,
        configuration,
        email,
        site_status,
        url_front,
        url_back,
        emailOfficiel,
        string_blacklist,
        domaines_blacklist
      }));
    } catch (e) {
      if (e.response.data.code === 'rest_invalid_magic_code' && window.location.pathname === '/') {
        yield put(displayModal(2, content));
      }
      yield put(forgotMagicLink());
      yield put(postStructureActionError(e.message));
    }
  }

  function* getHitForGtm(action: GET_HIT_FOR_GTM_ACTION): Saga<void> {
    const { magicLink } = yield select(getMagicLinkInState);

    try {
      const { id } = action.payload;
      const {
        code,
        embleme,
        nom,
        configuration,
        email,
        site_status,
        url_front,
        url_back,
        emailOfficiel,
        string_blacklist,
        domaines_blacklist,
      } = yield call(structureApi.getStructure, id, magicLink);
      yield put(getHitForGtmSuccess({
        code,
        embleme,
        nom,
        configuration,
        email,
        site_status,
        url_front,
        url_back,
        emailOfficiel,
        string_blacklist,
        domaines_blacklist,
      }));
      
    } catch (e) {
     
    }
  }

}
