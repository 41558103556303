import 'react-app-polyfill/ie11';

import React from 'react';
import ReactDOM from 'react-dom';
import { initWindowApp } from 'utils/windowUtils';
import App from 'components/app/AppProvider';
import TagManager from 'react-gtm-module';
import { GTM_ID } from 'utils/tagManagerUtils';

import registerServiceWorker from './registerServiceWorker';

import 'ie-array-find-polyfill';
import 'utils/ieUtils';
import './polyfill-includes';
import 'assets/css/style.css';
import 'assets/css/style_surcharge.css';

const initCookies = () => {
  const tagManagerArgs = {
    gtmId: GTM_ID,
    dataLayerName: 'dataLayer',
  };

  TagManager.initialize(tagManagerArgs);
};

initWindowApp();

const root = document.getElementById('root');
ReactDOM.render(<App acceptCookies={initCookies} />, root);

registerServiceWorker();
