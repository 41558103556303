// @flow

export const dynamicClassName = (e: string) => {
  let classNames: Array<string> = [...e.split(' ')] || e.split(' ');

  return {
    add: (cn: string) => classNames.push(cn),
    remove: (cn: string) => {
      classNames = classNames.filter((className: string): boolean => (className !== cn));
    },
    build: (): string => classNames.join(' ')
  };
};
