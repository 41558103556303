// @flow
import React, { PureComponent } from 'react';

import type { ConfigType, StructureType } from 'types/Structure';

export type StateProps = {
  structure: StructureType,
  structureID: number,
};

export type DispatchProps = {
  postStructureAction: (structureID: number, config: ConfigType, email: string) => void,
  hideModal: () => void,
};

type Props = {
  email: string,
} & StateProps & DispatchProps;

class RevokeForm extends PureComponent<Props> {
  _handleConfirm = () => {
    const { email, postStructureAction, hideModal, structure: { configuration }, structureID } = this.props;

    postStructureAction(
      structureID, 
      { ...configuration, admin: configuration.admin.filter((admin) => admin !== email) },
      email
    );
    hideModal();
  }

  render() {
    return (
     <a // eslint-disable-line
      className="btn btn--primary btn--full mb-2 mt-1 mt-md-2"
      onClick={this._handleConfirm}
      role="button"
      tabIndex={0}
    >
      Confirmer
     </a>
    );
  }
}

export default RevokeForm;
