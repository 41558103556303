// @flow
function getOnboardingBaseUrl(): string {
  let source = 'api.monsitecomite.ffr.nextmap.cloud';
  if (process.env.NODE_ENV === 'production') {
    source = 'api.'+window.location.hostname;
  }

  return `https://${source}`;
}

export const TIMEOUT = 15000;
export const ON_BOARDING_BASE_URL = getOnboardingBaseUrl() + '/wp-json';
export const FORM_CONTACT_TOKEN = 'YXBpLWZmcjowUmZ4TkdCJXl3V0huVTNsWWdENzdjNTI=';
