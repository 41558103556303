// @flow

import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import type { StructureType } from 'types/Structure';
import { nullLocation } from 'types/Router';

export type StateProps = {
  magicLink: string,
  structure: StructureType,
};

type Props = {
  component: React$ComponentType<any>,
  path: string,
  exact?: boolean,
  location?: { pathname: string, state: { fromCreationRoute?: boolean } | typeof undefined },
} & StateProps;

export default function CreationPrivateRoute({
  component,
  magicLink,
  structure: {
    configuration: {
      step
    }
  },
  path,
  exact,
  location
  }: Props) {

  /**
   * Si on tente d'aller autre part que dans le tunnel, on renvoie vers 404
   */
  const authorized_paths = [
    '/',
    '/creation/initialisation',
    '/creation/deleguer',
    '/creation/etape-1-demarrer',
    '/creation/etape-2-contenu',
    '/creation/etape-21-synchronisation',
    '/creation/etape-3-personnalisation',
    '/creation/etape-4-verification',
    '/creation/etape-5-recapitulatif',
    '/creation/validation',
    '/creation/monitoring',
  ];
  //@TODO monitoring 

  if (location && location.pathname === '/creation/etape-21-synchronisation') {
    return <Redirect to='/creation/etape-2-contenu' />;
  }
  if (location && location.pathname && authorized_paths.indexOf(location.pathname) === -1) {
    return <Redirect to='/404' />;
 }

  /**
   * Si on est sur l'étape 5, on ne peut revenir à d'autres étapes
   */
  const blocked_paths = [
    '/creation/initialisation',
    '/creation/deleguer',
    '/creation/etape-1-demarrer',
    '/creation/etape-2-contenu',
    '/creation/etape-21-synchronisation',
    '/creation/etape-3-personnalisation',
    '/creation/etape-4-verification',
    '/creation/etape-5-recapitulatif',
  ];

  if (magicLink !== '' && step && step === 5 && location && blocked_paths.indexOf(location.pathname) > -1) {
    return <Redirect to='/creation/validation' />;
  }

  return <Route exact={exact} path={path} component={component} />;
}

CreationPrivateRoute.defaultProps = {
  exact: false,
  location: nullLocation,
};
