// @flow

import { FETCH_SETTINGS_SUCCESS } from 'actions/appActions';
import type { liensRsType } from 'types/Settings';

import type { ActionType } from 'types/Actions';

export type State = {
  ligue: string,
  title: string,
  description: string,
  url: string,
  logo: string,
  logo_footer: string,
  logo_transparent: string,
  class_ligue: string,
  contacts: {
    adresse: string,
    adresse_map: string,
    telephone: string,
    email: string,
    horaires: string
  },
  favicons: {
    'android-192': string,
    'android-256': string,
    'apple-touch-icon-152': string,
    'apple-touch-icon-180': string,
    'favicon-16': string,
    'favicon-32': string,
    'mstile-150': string
  },
  liens_rs: liensRsType,
  newsletter: { type: false | 'campaign_monitor' | 'lien' | 'salesforce', lien?: string, key?: string },
  cookies: number,
  actualite_topbar: {
    link: string,
    title: string,
    label: string
  },
  domaine_multisite: string,
  pratiques_clubs: Array<string>,
  url_cgus: string,
  url_mentions_legales: string
};

const initialState: State = {
  ligue: '',
  title: '',
  description: '',
  url: '',
  logo: '',
  logo_footer: '',
  logo_transparent: '',
  class_ligue: '',
  contacts: {
    adresse: '',
    adresse_map: '',
    telephone: '',
    email: '',
    horaires: ''
  },
  favicons: {
    'android-192': '',
    'android-256': '',
    'apple-touch-icon-152': '',
    'apple-touch-icon-180': '',
    'favicon-16': '',
    'favicon-32': '',
    'mstile-150': ''
  },
  liens_rs: {
    fb_lien: '',
    instagram_lien: '',
    twitter_lien: '',
    youtube_lien: ''
  },
  newsletter: { type: false },
  cookies: 0,
  actualite_topbar: {
    link: '',
    title: '',
    label: ''
  },
  domaine_multisite: '',
  pratiques_clubs: [],
  url_cgus: '',
  url_mentions_legales: ''
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_SETTINGS_SUCCESS:
      return {
        ...state,
        ligue: action.payload.settings.ligue,
        title: action.payload.settings.title,
        description: action.payload.settings.description,
        url: action.payload.settings.url,
        logo: action.payload.settings.logo,
        logo_footer: action.payload.settings.logo_footer,
        logo_transparent: action.payload.settings.logo_transparent,
        contacts: action.payload.settings.contacts,
        favicons: action.payload.settings.favicons,
        liens_rs: action.payload.settings.liens_rs,
        newsletter: action.payload.settings.newsletter,
        cookies: action.payload.settings.cookies,
        actualite_topbar: action.payload.settings.actualite_topbar,
        domaine_multisite: action.payload.settings.domaine_multisite,
        url_cgus: action.payload.settings.url_cgus,
        url_mentions_legales: action.payload.settings.url_mentions_legales
      };
    default:
      return state;
  }
}
 