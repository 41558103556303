// @flow
import Modal from 'components/fragments/Modal';
import { connect } from 'react-redux';

import type { DispatchType, StateType } from 'types/Actions';
import type { DispatchProps, StateProps } from 'components/fragments/Modal';
import { displayModal, hideModal } from 'actions/modalActions';
import { forgotMagicLink, getModalForNewLink } from 'actions/magicLinkActions';

export default connect(
  (state: StateType): StateProps => ({
    modalNb: state.modalState.modalNb,
    force: state.modalState.force,
    titleLigue: state.settingsState.title,
    content: state.modalState.content,
    emailUser: state.magicLinkState.email,
  }),
  (dispatch: DispatchType): DispatchProps => ({
    hideModal: () => dispatch(hideModal()),
    forgotMagicLink: () => dispatch(forgotMagicLink()),
    getModalForNewLink : () => dispatch(getModalForNewLink()),
    displayModal: (modalNb: number, content: Object, force: boolean) => dispatch(displayModal(modalNb, content, force)),
  })
)(Modal);
