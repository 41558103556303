/// @flow
import React, { PureComponent } from 'react';
import { dynamicClassName } from '../../utils/dynamicClassName';

type Props = {
  message?: string,
  forceDisplay?: boolean,
};

class ErrorMessage extends PureComponent<Props> {
  static defaultProps = {
    message: 'Adresse email invalide',
    forceDisplay: false,
  };

  render() {
    const { forceDisplay, message } = this.props;
    const classNames = dynamicClassName('input__error');

    return <span style={forceDisplay ? { display: 'block' } : {}} className={classNames.build()}>{message}</span>;
  }
}

export default ErrorMessage;
