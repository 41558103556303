/// @flow
import React, { PureComponent } from 'react';

import { dynamicClassName } from 'utils/dynamicClassName';
import ErrorMessage from 'components/fragments/ErrorMessage';

type ClassNameType = {
  add: Function,
  build: Function,
  remove: Function
};

type Props = {
  id: string,
  label: string,
  placeholder?: string,
  className?: string,
  errorMessage?: string,
  hasError?: boolean,
  isDark?: boolean,
  isLight?: boolean,
  type?: string,
  defaultValue?: string | null,
  disable?: boolean,
  withEditButton?: boolean,
  buttonLabel?: string,
  isEmpty?: boolean,
  name?: string,
  needNameProps?: boolean,
  rowInput?: boolean,
  onChange?: (value: string, name?: string) => void,
  onFocus?: () => void,
  onBlur?: () => void,
  onButtonClick?: () => void,
};

class TextInputMaterial extends PureComponent<Props> {
  static defaultProps = {
    type: 'text',
    disable: false,
    placeholder: '',
    className: '',
    errorMessage: undefined,
    hasError: false,
    isDark: false,
    isLight: false,
    defaultValue: null,
    withEditButton: false,
    buttonLabel: '',
    isEmpty: false,
    name: '',
    needNameProps: false,
    rowInput: false,
    onChange: () => {},
    onFocus: () => {},
    onBlur: () => {},
    onButtonClick: () => {},
  };

  _handleInputChanged = (e: any) => {
    const { onChange , needNameProps, name } = this.props;

    if (onChange && needNameProps && name) {
      onChange(e.target.value, e.target.name);
    } else if (onChange) {
      onChange(e.target.value);
    };
  };

  _onInputStatusChange = (event: SyntheticFocusEvent<EventTarget>, isFocus: boolean) => {
    const { target } = event;
    const { placeholder } = this.props;

    if (target instanceof HTMLInputElement) {
      if (isFocus || target.value !== '') {
        target.classList.remove('is-empty');
      } else if (!placeholder) {
        target.classList.add('is-empty');
      }
    }
  }

  renderInput = (classNames: ClassNameType) => {
    const {
      type,
      id,
      label,
      defaultValue,
      placeholder,
      onFocus,
      hasError,
      onBlur,
      name,
    } = this.props;

    return (
      <div className={classNames.build()}>
        <input
          defaultValue={defaultValue && defaultValue}
          type={type}
          id={id}
          className={!defaultValue && !placeholder ? 'is-empty' : '' }
          onChange={this._handleInputChanged}
          onFocus={(event) => {
            this._onInputStatusChange(event, true);
            onFocus && onFocus();
          }}
          onBlur={(event) => {
            this._onInputStatusChange(event, false);
            onBlur && onBlur();
          }}
          name={name && name}
          placeholder={placeholder}
          />
        <label htmlFor={id} data-label={label}>
          {label}
        </label>
        {hasError && <i className="icon icon-info" />}
      </div>
    );
  }

  handleButtonClick = () => {
    const { onButtonClick } = this.props;
    if (onButtonClick) onButtonClick();
  }

  render() {
    const {
      renderInput,
      handleButtonClick,
      props: {
        isDark,
        isLight,
        hasError,
        className,
        disable,
        errorMessage,
        withEditButton,
        buttonLabel,
        isEmpty,
        rowInput,
      },
    } = this;

    const classNames = dynamicClassName('input');
    classNames.add('input--material');
    className && classNames.add(className);
    isDark && classNames.add('is-dark');
    isLight && classNames.add('input--light');
    hasError && classNames.add('has-error');
    disable && classNames.add('input--no-edit');

    if (withEditButton && buttonLabel && rowInput) {
      return (
        <div className='row-input mt-2 mt-md-3'>
          {!isEmpty
            ? renderInput(classNames)
            : <div className='input input--placeholder' />}
          <button
            className='btn btn--white btn--edit'
            type='button'
            onClick={handleButtonClick}>
            {buttonLabel}
          </button>
        </div>
      );
    } else if (withEditButton && buttonLabel) {
      return (
        <div className='input-with-edit'>
          {!isEmpty
            ? renderInput(classNames)
            : <div className='input input--placeholder' />}
          <button
            className='btn btn--white btn--edit'
            type='button'
            onClick={handleButtonClick}>
            {buttonLabel}
          </button>
        </div>
      );
    }
    return (
      <>
        {renderInput(classNames)}
        {hasError && errorMessage && <ErrorMessage message={errorMessage} />}
      </>
    );
  }
}

export default TextInputMaterial;
