// @flow

import { POST_ACTION_SUCCESS, GET_HIT_FOR_GTM_SUCCESS } from 'actions/structureActions';
import { FETCH_SETTINGS_SUCCESS } from 'actions/appActions';
import { ADD_ADMIN_DELEGATION } from 'actions/magicLinkActions';

import type { POST_ACTION_SUCCESS_ACTION, GET_HIT_FOR_GTM_SUCCESS_ACTION } from 'actions/structureActions';
import type { FETCH_SETTINGS_SUCCESS_ACTION } from 'actions/appActions';
import type { ADD_ADMIN_DELEGATION_ACTION } from 'actions/magicLinkActions';

export const hitTagsArray = [ 
  FETCH_SETTINGS_SUCCESS,
  POST_ACTION_SUCCESS,
  ADD_ADMIN_DELEGATION,
  GET_HIT_FOR_GTM_SUCCESS,
];
 
export type hitTagsArrayType = FETCH_SETTINGS_SUCCESS_ACTION
                            | POST_ACTION_SUCCESS_ACTION
                            | ADD_ADMIN_DELEGATION_ACTION
                            | GET_HIT_FOR_GTM_SUCCESS_ACTION;
