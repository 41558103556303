// @flow
import CreationPrivateRoute from 'components/app/CreationPrivateRoute';
import { connect } from 'react-redux';

import type { StateType } from 'types/Actions';
import type { StateProps } from 'components/app/CreationPrivateRoute';

export default connect(
  (state: StateType): StateProps => ({
    magicLink: state.magicLinkState.magicLink,
    structure: state.structureState.structure,
  }),
)(CreationPrivateRoute);
