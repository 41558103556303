// import ReduxThunk from 'redux-thunk';
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import reducers from 'reducers/index';
import rootSaga from 'sagas/root';
import keycloakMiddleware from 'sagas/keycloakMiddleware';

const sagaMiddleware = createSagaMiddleware();
const persistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'appState',
    'newsletterState',
    'tagsState',
    'menuState',
    'magicLinkState',
  ],
};
const persistedReducer = persistReducer(persistConfig, reducers);

export const store = createStore(
  persistedReducer,
  {},
  applyMiddleware(sagaMiddleware, keycloakMiddleware),
);
export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);
