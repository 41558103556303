//@flow
type LocationShape = {
  pathname?: string,
  search?: string,
  hash?: string,
  state?: any
};

type HistoryAction = "PUSH" | "REPLACE" | "POP";

type Match = {
  params: { [key: string]: ?string },
  isExact: boolean,
  path: string,
  url: string
};

export type Location = {
  pathname: string,
  search: string,
  hash: string,
  state?: any,
  key?: string
};

type RouterHistory = {
  length: number,
  location: Location,
  action: HistoryAction,
  listen(
    callback: (location: Location, action: HistoryAction) => void
  ): () => void,
  push(path: string | LocationShape, state?: any): void,
  replace(path: string | LocationShape, state?: any): void,
  go(n: number): void,
  goBack(): void,
  goForward(): void,
  canGo?: (n: number) => boolean,
  block(
    callback: (location: Location, action: HistoryAction) => boolean
  ): void,
  index?: number,
  entries?: Array<Location>
};

export type RouterProps = {
  history: RouterHistory,
  location: Location,
  match: Match,
};

export const nullLocation: Location = {
  pathname: '',
  search: '',
  hash: '',
  state: {},
  key: ''
};
