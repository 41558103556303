// @flow

import { STATUS_LOADING, STATUS_FAILURE, STATUS_SUCCESS } from 'constants/statusConstants';
import {
  FETCH_STRUCTURE_START,
  FETCH_STRUCTURE_SUCCESS,
  FETCH_STRUCTURE_FAILURE,
  POST_CONFIG_START,
  POST_CONFIG_SUCCESS,
  POST_CONFIG_FAILURE,
  POST_ACTION_START,
  POST_ACTION_SUCCESS,
  POST_ACTION_FAILURE,
} from 'actions/structureActions';
import { FORGOT_MAGIC_LINK } from 'actions/magicLinkActions';

import { nullStructure } from 'types/Structure';

import type { StructureType } from 'types/Structure';
import type { Status } from 'types/Status';
import type { ActionType } from 'types/Actions';

export type State = {
  structure: StructureType,
  status: Status,
  postStatus: Status
};

const initialState: State = {
  structure: nullStructure,
  status: STATUS_LOADING,
  postStatus: STATUS_SUCCESS,
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case FETCH_STRUCTURE_START:
      return {
        ...state,
        status: STATUS_LOADING
      };
    case FETCH_STRUCTURE_SUCCESS:
      return {
        ...state,
        structure: {
          ...state.structure,
          ...action.payload.structure,
          configuration: {
            ...state.structure.configuration,
            ...action.payload.structure.configuration,
          },
        },
        status: STATUS_SUCCESS
      };
    case FETCH_STRUCTURE_FAILURE:
      return {
        ...state,
        status: STATUS_FAILURE
      };
    case POST_CONFIG_START:
    case POST_ACTION_START:
      return {
        ...state,
        // status: STATUS_LOADING,
        postStatus: STATUS_LOADING,
      };
    case POST_CONFIG_SUCCESS:
    case POST_ACTION_SUCCESS:
      return {
        ...state,
        structure: {
          ...state.structure,
          ...action.payload.structure,
          configuration: {
            ...state.structure.configuration,
            ...action.payload.structure.configuration,
          },
        },
        // status: STATUS_SUCCESS,
        postStatus: STATUS_SUCCESS,
      };
    case POST_CONFIG_FAILURE:
    case POST_ACTION_FAILURE:
      return {
        ...state,
        // status: STATUS_FAILURE,
        postStatus: STATUS_FAILURE,
      };
    case FORGOT_MAGIC_LINK:
      return {
        ...state,
        structure: initialState.structure,
      };
    default:
      return state;
  }
}
