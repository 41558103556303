// @flow
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import CreationRequestForm from 'containers/creationSteps/initialStep/CreationRequestFormContainer';
import AddAdminForm from 'containers/creationSteps/monitoring/AddAdminFormContainer';
import RevokeForm from 'containers/creationSteps/monitoring/RevokeFormContainer';
import SupportContact from 'containers/creationSteps/monitoring/SupportContactContainer';
import { getPublicAssets } from 'utils/assetsUtils';
import ReAskMagicLink from 'containers/creationSteps/ReAskMagicLinkContainer';
import Button from 'components/fragments/button/Button';

export type StateProps = {
  modalNb?: number,
  content?: Object,
  force?: boolean,
  email?: string,
  emailUser?: string,
};

export type DispatchProps = {
  hideModal: () => void,
  getModalForNewLink: () => void,
};

type Props = StateProps & DispatchProps;

type State = {
  isOpen: boolean,
};
class Modal extends Component <Props, State> {
  static defaultProps = {
    modalNb: 0,
    force: false,
    content: undefined,
    email: '',
    emailUser: ''
  };

  state: State = {
    isOpen: false,
  }

  /**
   * Avant l'update, vérifier que l'on puisse faire cet update
   * Une modale ouverte ne peut être remplacée, sauf par la modale 0 ou si on force le changement
   */
  shouldComponentUpdate(nextProps: Props) {
    const { modalNb, force } = nextProps;
    const { isOpen } = this.state;
    if (isOpen && (modalNb !== 0 && !force)) {
      return false;
    }
    return true;
  }

  /**
   * Au changement de numéro de modal,
   * si le numéro est à 0, on indique qu'elle est fermée, sinon ouverte
   */
  componentDidUpdate(prevProps: Props) {
    const { modalNb } = this.props;

    if (prevProps.modalNb !== modalNb) {
      if (modalNb === 0) {
        this.setState({isOpen: false});
      } else {
        this.setState({isOpen: true});
      }
    }
  }

  componentDidMount (){
    const { email, hideModal } = this.props;

    if (email === ''){
      hideModal();
    }
  }

  renderSuccessModal = () => {
    const { hideModal, content } = this.props;

    return (
      <section className="modal visible">
        <div className="container">
          <div className="modal__overlay">
          </div>
          <div className="modal-content box newsletter-valid ">
            <i className="icon icon-close js-closeModal" role="button" tabIndex={0} onClick={hideModal}></i>
            <div className="modal__wrapper">
              <div className="modal__body modal__body--medium narrow-2">
                <div className="circle-icon circle-icon--blue"></div>
                <h3 className="mb-1 mt-2 ft-h2">{content ? content.title : ''}</h3>
                <p className="mb-1 ft-center">
                  {content ? content.text : ''}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderErrorModal = () => {
    const { hideModal, content } = this.props;
    const words = 'Nous avons rencontré un problème';
    const txt = 'Merci de vérifier les informations envoyées !';

    return (
      <section className="modal visible">
        <div className="container">
          <div className="modal__overlay">
          </div>
          <div className="modal-content box newsletter-unsubscribe-done modal-content--stripe">
            <i className="icon icon-close js-closeModal" role="button" tabIndex={0} onClick={hideModal} />
            <div className="modal__wrapper">
              <h3 className="mb-2">{content ? content.title : words}</h3>
              <div className="modal__body modal__body--medium narrow-2 mb-3">
                <div className="circle-icon circle-icon--alert"></div>
                <p>{content ? content.text : txt}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderFailureModal = (wording: ?string) => {
    const { hideModal, content } = this.props;
    const words = wording || 'Nous avons rencontré un problème lors de l\'inscription';
    const txt = 'Merci de vérifier les informations envoyées !';

    return (
      <section className="modal visible">
        <div className="container">
          <div className="modal__overlay">
          </div>
          <div className="modal-content box newsletter-unsubscribe-done modal-content--stripe">
            <i className="icon icon-close js-closeModal" role="button" tabIndex={0} onClick={hideModal} />
            <div className="modal__wrapper">
              <h3 className="mb-2">{content ? content.title : words}</h3>
              <div className="modal__body modal__body--medium narrow-2 mb-3">
                <div className="circle-icon circle-icon--alert"></div>
                <p>{content ? content.text : txt}</p>
                <button type='button'>Envoyer</button>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderReAskMagicLink = () => {
    const { hideModal, content } = this.props;

    return (
      <section className="modal visible">
        <div className="container">
          <div className="modal__overlay">
          </div>
          <div className="modal-content box newsletter-valid ">
            <i className="icon icon-close js-closeModal" role="button" tabIndex={0} onClick={hideModal}></i>
            <div className="modal__wrapper">
              <h3 className="mb-2">{content ? content.title : ''}</h3>
              <div className="modal__body modal__body--medium narrow-2">
                <ReAskMagicLink />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderModalNewsletterMissingOpt = () => {
    const { hideModal } = this.props;

    return (
      <section className="modal visible">
        <div className="container">
          <div className="modal__overlay">
          </div>
          <div className="modal-content box newsletter-unsubscribe-done modal-content--stripe">
            <i className="icon icon-close js-closeModal" role="button" tabIndex={0} onClick={hideModal}></i>
            <div className="modal__wrapper">
              <h3 className="mb-2">Pour vous inscrire, merci d&apos;accepter les conditions de la FFR</h3>
              <div className="modal__body modal__body--medium narrow-2 mb-3">
                <div className="circle-icon circle-icon--alert"></div>
                <p></p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderModalContactFormSuccess = () => {
    const { content, hideModal } = this.props;
    return (
      <section className="modal visible">
        <div className="container">
          <div className="modal__overlay">
          </div>
          <div className="modal-content box keep-in-touch-done modal-content--stripe">
            <i className="icon icon-close js-closeModal" role="button" tabIndex={0} onClick={hideModal}></i>
            <div className="modal__wrapper">
              <h3 className="mb-2">Votre demande a bien été envoyée</h3>
              <div className="modal__body modal__body--medium narrow-2">
                <div className="circle-icon circle-icon--check"></div>
                <p>Merci !</p>
                { content && content.redirection &&
                  <Redirect to={ content.redirection } />}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderModalContactFormFailed = () => {
    const { hideModal } = this.props;

    return (
      <section className="modal visible">
        <div className="container">
          <div className="modal__overlay">
          </div>
          <div className="modal-content box newsletter-unsubscribe-done modal-content--stripe">
            <i className="icon icon-close js-closeModal" role="button" tabIndex={0} onClick={hideModal}></i>
            <div className="modal__wrapper">
              <h3 className="mb-2">Nous avons rencontré un problème lors de l&apos;envoi de votre message</h3>
              <div className="modal__body modal__body--medium narrow-2 mb-3">
                <div className="circle-icon circle-icon--alert"></div>
                <p>Merci de vérifier les informations envoyées!</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderCreationRequestModal = () => {
    const { hideModal } = this.props;

    return (
      <section className='modal visible'>
        <div className='container'>
          <div className='modal__overlay' />
          <div className='modal-content box site-club-creation-comite'>
            <i className='icon icon-close js-closeModal' role='button' tabIndex={0} onClick={hideModal} />
            <div
              className='modal__header'
              style={{ backgroundImage: `url(${getPublicAssets('/img/banner/banner-modal-site-comite.jpg')})`}}>
              <h3 className='ft-h2 mb-2'>
                Création de
                <br />
                {' '}
                votre site comité
              </h3>
            </div>
            <div className='modal__wrapper'>
              <CreationRequestForm />
            </div>
              <div className='block-infos-generales'>
                <div
                  ref={(ref) => window.snack = ref }
                  className='form-action-success'
                  style={{ backgroundColor: '#cc2e3b'}}>
                  <i className="icon icon-shield" />
                  <p className="ft-500 mb-0">Votre adresse e-mail ne peut être vérifiée</p>
                </div>
              </div>
          </div>
        </div>
      </section>
    );
  }

  renderAddAdmin = () => {
    const { hideModal } = this.props;

    return (
      <section className='modal visible'>
        <div className='container'>
          <div className='modal__overlay' />
          <div className="modal-content box site-club-add-admin ">
            <i className="icon icon-close js-closeModal" role='button' tabIndex={0} onClick={hideModal}></i>
            <div
              className="modal__header"
              style={{ backgroundImage: `url(${getPublicAssets('/img/banner/banner-modal-site-club-add.jpg')})`}}
            >
            <h3 className="ft-h2 mb-2">
              Ajouter un
              <br />
              {' '}
              administrateur
            </h3>
            </div>
          <div className="modal__wrapper">
            <div className="modal__body modal__body--medium mb-3 mb-md-5">
              <p className="mb-2">
                Renseignez l&apos;adresse email de la personne à qui vous souhaitez
                {' '}
                donner les accès à l&apos;administration du site.
              </p>
              <p className="ft-700 mb-0">
                Elle recevra alors un email avec toutes les informations nécessaires
                {' '}
                pour rejoindre l’équipe de modération.
              </p>
            </div>
            <AddAdminForm />
          </div>
          </div>
        </div>
      </section>
    );
  }

  renderReminder = () => {
    const { hideModal, content } = this.props;
    const { email } = content || {};

    return (
      <section className='modal visible'>
        <div className='container'>
          <div className='modal__overlay' />
          <div className="modal-content box site-club-reminder">
            <i className="icon icon-close js-closeModal" role='button' tabIndex={0} onClick={hideModal}></i>
            <div className="modal__wrapper">
              <p className="ft-secondary ft-700 mb-2">Rappel envoyé!</p>
              <p className="mb-2">
                Un mail contenant un lien vers l&apos;administration du site à
                {' '}
                été envoyé à l’adresse :
                {` ${email}`}
              </p>
              <a // eslint-disable-line
                className="btn btn--primary btn--full mb-1 mt-1"
                onClick={hideModal}
                role="button"
                tabIndex={0}
              >
                Retour à l&apos;administration
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderRevoke = () => {
    const { hideModal, content } = this.props;

    const { email } = content || {};

    return (
      <section className='modal visible'>
        <div className='container'>
          <div className='modal__overlay' />
          <div className="modal-content box site-club-revoke">
            <i className="icon icon-close js-closeModal" role="button" tabIndex={0} onClick={hideModal}></i>
            <div className="modal__wrapper">
              <p className="mb-2">
                Vous êtes sur le point de révoquer les droits d&apos;administrateur à
                {` ${email || ''}`}
              </p>
              <p className="ft-secondary ft-700 mb-2">
                Attention, ce choix est définitif et cette adresse mail
                {' '}
                ne pourra plus être ajouter aux administrateurs du site.
              </p>
              <RevokeForm email={email || ''} />
              <a // eslint-disable-line
                className="btn btn--white btn--full mb-1"
                onClick={hideModal}
                role="button"
                tabIndex={0}
              >
                Annuler
              </a>
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderSupportContact = () => {
    const { hideModal } = this.props;

    return (
      <section className='modal visible'>
        <div className='container'>
          <div className='modal__overlay' />
          <div className="modal-content box site-club-revoke">
            <i className="icon icon-close js-closeModal" role="button" tabIndex={0} onClick={hideModal}></i>
            <div className="modal__wrapper">
              <p className="mb-2">
                Contacter le support
              </p>
              <SupportContact />
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderLogoutAlert = () => {
    const { hideModal, getModalForNewLink} = this.props;
 
    return (
      <section className="modal visible">
        <div className="container">
          <div className="modal__overlay">
          </div>
          <div className="modal-content box newsletter-valid ">
            <i className="icon icon-close js-closeModal" role="button" tabIndex={0} onClick={hideModal}></i>
            <div className="modal__wrapper">
              <div className="modal__body modal__body--medium narrow-2">
                <div className="circle-icon circle-icon--blue"></div>
                <h3 className="mb-1 mt-2 ft-h2">Déconnexion</h3>
                <p className="mb-1 ft-center">
                  Voulez-vous vraiment quitter la création du site ?
                  <br />
                  (Vous pourrez y revenir plus tard)
                </p>
              </div>
              <div>
                <Button
                  label='Se déconnecter'
                  onClick={getModalForNewLink}
                  className="mb-2 mt-1 mt-md-2"
                  isFull
                />
                <Button
                  label='Annuler'
                  onClick={hideModal}
                  className="btn--white btn--full mb-1"
                  isFull
                  isSecondary
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  renderLogoutSuccess = () => {
    const { hideModal, content, emailUser} = this.props;

    return (
      <section className="modal visible">
        <div className="container">
          <div className="modal__overlay">
          </div>
          <div className="modal-content box newsletter-valid ">
            <i className="icon icon-close js-closeModal" role="button" tabIndex={0} onClick={hideModal}></i>
            <div className="modal__wrapper">
              <div className="modal__body modal__body--medium narrow-2">
                <h3 className="mb-1 mt-2 ft-h2">{content ? content.title : ''}</h3>
                <p className="mb-1 ft-center">{content ? content.text : ''}</p>
                <div>
                  <Button
                    label={`Ok j'ai compris`}
                    onClick={hideModal}
                    className="mb-2 mt-1 mt-md-2 btn--white"
                    isFull
                    isSecondary
                  />
                   {emailUser && 
                  <ReAskMagicLink />}
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  render(){
    const { modalNb } = this.props;
    switch (modalNb) {
      case 0:
        return null;
      case 1:
        return this.renderSuccessModal();
      case 2:
        return this.renderReAskMagicLink();
      case 21:
        const wording =
        'Vous êtes déjà inscrit mais vous n’avez pas validé votre adresse. Nous vous invitons à consulter vos emails.';
        return this.renderFailureModal(wording);
      case 3:
        return this.renderModalNewsletterMissingOpt();
      case 4:
        return this.renderModalContactFormSuccess();
      case 5:
        return this.renderModalContactFormFailed();
      case 6:
        return this.renderCreationRequestModal();
      case 7:
        return this.renderAddAdmin();
      case 8:
        return this.renderReminder();
      case 9:
        return this.renderRevoke();
      case 10:
        return this.renderSupportContact();
      case 11:
        return this.renderLogoutAlert();
      case 12:
        return this.renderLogoutSuccess();
      case 13:
        return this.renderErrorModal();
      default:
        return null;
    }
  }
}

export default Modal;
