// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import { getOnboardingMagicHeader } from 'utils/requestUtils';

function MagicLinkApi(executor: ApiExecutorType) {
  return { askMagicLink, addAdminDelegation };

  function askMagicLink(email: string): Promise<any> {
    return executor.post('ffr/v1/onboarding_magic', { email });
  }

  function addAdminDelegation(email: string, magicLink: string, clubid: number) {
    const action = 'add_delegation'; 
    return executor.post('ffr/v1/onboarding_magic', { email, action, clubid }, getOnboardingMagicHeader(magicLink));    
  }
};

export default MagicLinkApi;
