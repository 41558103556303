// @flow

import type { StructureType, ConfigType } from 'types/Structure';

export const FETCH_STRUCTURE_START = 'FETCH_STRUCTURE_START';
export const FETCH_STRUCTURE_SUCCESS = 'FETCH_STRUCTURE_SUCCESS';
export const FETCH_STRUCTURE_FAILURE = 'FETCH_STRUCTURE_FAILURE';
export const POST_CONFIG_START = 'POST_CONFIG_START';
export const POST_CONFIG_SUCCESS = 'POST_CONFIG_SUCCESS';
export const POST_CONFIG_FAILURE = 'POST_CONFIG_FAILURE';
export const POST_ACTION_START = 'POST_ACTION_START';
export const POST_ACTION_SUCCESS = 'POST_ACTION_SUCCESS';
export const POST_ACTION_FAILURE = 'POST_ACTION_FAILURE';
export const GET_HIT_FOR_GTM = 'GET_HIT_FOR_GTM';
export const GET_HIT_FOR_GTM_SUCCESS = 'GET_HIT_FOR_GTM_SUCCESS';

export type FETCH_STRUCTURE_START_ACTION = {
  type: 'FETCH_STRUCTURE_START',
  payload: {
    id: number
  }
};

export type FETCH_STRUCTURE_FAILURE_ACTION = {
  type: 'FETCH_STRUCTURE_FAILURE'
};

export type FETCH_STRUCTURE_SUCCESS_ACTION = {
  type: 'FETCH_STRUCTURE_SUCCESS',
  payload: {
    structure: StructureType,
  }
};
export type POST_CONFIG_START_ACTION = {
  type: 'POST_CONFIG_START',
  payload: {
    id: number,
    config: ConfigType,
  }
};

export type POST_CONFIG_FAILURE_ACTION = {
  type: 'POST_CONFIG_FAILURE'
};

export type POST_CONFIG_SUCCESS_ACTION = {
  type: 'POST_CONFIG_SUCCESS',
  payload: {
    structure: StructureType
  }
};

export type POST_ACTION_START_ACTION = {
  type: 'POST_ACTION_START',
  payload: {
    id: number,
    config: ConfigType,
    action: string,
    email: string,
  }
};

export type POST_ACTION_FAILURE_ACTION = {
  type: 'POST_ACTION_FAILURE'
};

export type POST_ACTION_SUCCESS_ACTION = {
  type: 'POST_ACTION_SUCCESS',
  payload: {
    structure: StructureType
  }
};

export type GET_HIT_FOR_GTM_ACTION = {
  type: 'GET_HIT_FOR_GTM',
  payload: {
    id: number
  }
}

export type GET_HIT_FOR_GTM_SUCCESS_ACTION = {
  type: 'GET_HIT_FOR_GTM_SUCCESS',
  payload: {
    structure: StructureType,
  }
}

export type Action =
| FETCH_STRUCTURE_START_ACTION
| FETCH_STRUCTURE_FAILURE_ACTION
| FETCH_STRUCTURE_SUCCESS_ACTION
| POST_CONFIG_START_ACTION
| POST_CONFIG_FAILURE_ACTION
| POST_CONFIG_SUCCESS_ACTION
| POST_ACTION_START_ACTION
| POST_ACTION_FAILURE_ACTION
| POST_ACTION_SUCCESS_ACTION
| GET_HIT_FOR_GTM_ACTION
| GET_HIT_FOR_GTM_SUCCESS_ACTION;


export function fetchStructure(id: number): FETCH_STRUCTURE_START_ACTION {
  return {
    type: FETCH_STRUCTURE_START,
    payload: { id }
  };
}

export function structureFetched(structure: StructureType): FETCH_STRUCTURE_SUCCESS_ACTION {
  return {
    type: FETCH_STRUCTURE_SUCCESS,
    payload: { 
      structure,
    }
  };
}

export function structureFetchError(error: any): FETCH_STRUCTURE_FAILURE_ACTION {
  return {
    type: FETCH_STRUCTURE_FAILURE
  };
}

export function postStructureConfig(id: number, config: ConfigType): POST_CONFIG_START_ACTION {
  return {
    type: POST_CONFIG_START,
    payload: {
      id,
      config,
    }
  };
}

export function postStructureConfigSuccess(structure: StructureType): POST_CONFIG_SUCCESS_ACTION {
  return {
    type: POST_CONFIG_SUCCESS,
    payload: { structure }
  };
}

export function postStructureConfigError(error: any): POST_CONFIG_FAILURE_ACTION {
  return {
    type: POST_CONFIG_FAILURE
  };
}

export function postStructureAction(
  id: number,
  config: ConfigType,
  action: string,
  email: string
): POST_ACTION_START_ACTION {
  return {
    type: POST_ACTION_START,
    payload: {
      id,
      config,
      action,
      email,
    }
  };
}

export function postStructureActionSuccess(structure: StructureType): POST_ACTION_SUCCESS_ACTION {
  return {
    type: POST_ACTION_SUCCESS,
    payload: { structure }
  };
}

export function postStructureActionError(error: string): POST_ACTION_FAILURE_ACTION {
  return {
    type: POST_ACTION_FAILURE
  };
}

export function getHitForGtm (id: number): GET_HIT_FOR_GTM_ACTION {
  return {
    type: GET_HIT_FOR_GTM,
    payload: { id }
  };
}

export function getHitForGtmSuccess(structure: StructureType): GET_HIT_FOR_GTM_SUCCESS_ACTION {
  return {
    type: GET_HIT_FOR_GTM_SUCCESS,
    payload: { structure }
  };
}
