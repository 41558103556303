// @flow

import React, { PureComponent } from 'react';

import TextInputMaterial from 'components/fragments/input/material/TextInputMaterial';
import Button from 'components/fragments/button/Button';
import { validateEmail } from 'utils/validatorUtils';

export type DispatchProps = {
  askMagicLink: (email: string) => void,
};

type Props = DispatchProps;

type State = {
  email: string,
};

export default class CreationRequestForm extends PureComponent<Props, State> {
  state = {
    email: '',
  };

  handleChange = (value: string) => {
    this.setState(prevState => ({
      ...prevState,
      email: value,
    }));
  }

  handleSubmit = () => {
    const { state: { email }, props: { askMagicLink } } = this;
    askMagicLink(email);
  }

  render() {
    const { handleChange, handleSubmit, state: { email } } = this;

    return (
      <>
        <div className='modal__body modal__body--medium mb-3 mb-md-5'>
          <p className='mb-2'>
            Pour créer votre site, nous avons besoin
            de vérifier que vous appartenez bien à un comité. 
          </p>
          <p className='ft-700 mb-2 ft-black'>
            Renseignez, ci-dessous, l&apos;adresse e-mail officielle de votre comité (sous la forme A11A@ffr.fr)
            {' '}
            sur laquelle les emails de la Fédération sont envoyés. Vous
            recevrez alors un e-mail avec toutes les informations nécessaires à la création de votre site.
          </p>
        </div>
        <TextInputMaterial
          id='YsfabyzSgwsw'
          label='Adresse e-mail FFR du comité'
          className='mb-3'
          hasError={!validateEmail(email) && email !== ''}
          errorMessage="Votre e-mail n'est pas valide"
          onChange={handleChange} />
        <Button
          label='Envoyer'
          isDisabled={!validateEmail(email)}
          onClick={handleSubmit}
          isFull />
      </>
    );
  }
}
