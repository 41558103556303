export function setBodyStyle(styles) {
  if (!document.body) {
    return;
  }

  const keys = Object.keys(styles);

  keys.forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(styles, key)) {
      document.body.style[key] = styles[key];
    }
  });
}

export function setBodyClassName(className) {
  if (!document.body) {
    return;
  }
  document.body.className = className;
}

export function resetBodyStyle() {
  if (!document.body) {
    return;
  }

  document.body.style = null;
}
