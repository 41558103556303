// @flow
import React from 'react';
import LazyloadLibrary from 'react-lazyload';
import Loading from 'components/fragments/Loading';

type Props = {
  height?: string,
  autoSize?: boolean,
  children: any,
  isWhite?: boolean,
  isBlue?: boolean,
  isDarkBlue?: boolean,
};

function getHeight({ autoSize }: Props): Object {
  if (autoSize) {
    return { height: '100%' };
  }

  return {};
}

const LazyLoad = (props: Props) => {
  const { isWhite, isBlue, isDarkBlue, children } = props;
  return (
    <LazyloadLibrary
      once
      offset={200}
      placeholder={<Loading isWhite={isWhite} isBlue={isBlue} isDarkBlue={isDarkBlue} />}
      {...getHeight(props)}
      {...props}>
      {children}
    </LazyloadLibrary>
  );
};

LazyLoad.defaultProps = {
  autoSize: true,
  isWhite: false,
  isBlue: false,
  isDarkBlue: false,
  height: undefined,
};

export default LazyLoad;
