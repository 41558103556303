/// @flow
import React, { PureComponent } from 'react';
import { dynamicClassName } from 'utils/dynamicClassName';
import ErrorMessage from 'components/fragments/ErrorMessage';

type Props = {
  id: string,
  placeholder?: string,
  label: string,
  className?: string,
  errorMessage?: string,
  hasError?: boolean,
  isDark?: boolean,
  isLight?: boolean,
  onChange?: (value: string) => void,
  customClassname?: string,
};

class TextareaInputMaterial extends PureComponent<Props> {
  static defaultProps = {
    placeholder: '',
    className: '',
    errorMessage: '',
    hasError: false,
    isDark: false,
    isLight: false,
    onChange: () => {},
    customClassname: '',
  };

  _handleInputChanged = (e: any) => {
    const { onChange } = this.props;

    if (onChange) onChange(e.target.value);
  };

  _onInputStatusChange = (event: SyntheticFocusEvent<EventTarget>, isFocus: boolean) => {
    const { target } = event;
    const { placeholder } = this.props;

    if (target instanceof HTMLTextAreaElement) {
      if (isFocus || target.value !== '') {
        target.classList.remove('is-empty');
      } else if (!placeholder) {
        target.classList.add('is-empty');
      }
    }
  }

  render() {
    const {
      isDark,
      isLight,
      hasError,
      className,
      placeholder,
      id,
      label,
      customClassname,
      errorMessage,
    } = this.props;

    const classNames = dynamicClassName('input');
    classNames.add('input--material');
    className && classNames.add(className);
    isDark && classNames.add('is-dark');
    isLight && classNames.add('input--light');
    hasError && classNames.add('has-error');
    customClassname && classNames.add(customClassname);

    return (
      <>
        <div className={classNames.build()}>
          <textarea
            id={id}
            className={!placeholder ? 'is-empty' : ''}
            placeholder={placeholder}
            onChange={this._handleInputChanged}
            onFocus={(event) => this._onInputStatusChange(event, true)}
            onBlur={(event) => this._onInputStatusChange(event, false)}
            />
          <label htmlFor={id} data-label={label}>
            {label}
          </label>
          {hasError && <i className="icon icon-info" />}
        </div>
        {hasError && errorMessage && <ErrorMessage message={errorMessage} />}
      </>
    );
  }
}

export default TextareaInputMaterial;
