export const baseMenuItemWidth = 45;
export const baseHeaderCategorySearchWidth = 58;
export const letterWidth = 5;
export const letterWidthSearch = 9;
export const logoWidth = 100;
export const footerBlacklistPaths = [
  '/trouver-un-club-de-rugby/resultats-de-recherche',
  '/trouver-un-club-de-rugby/resultats-de-recherche/',
  '/ffr-tv-player/'
];
export const headerBlacklistWithSlugPaths = ['ffr-tv-player'];
export const footerBlacklistWithSlugPaths = ['ffr-tv-player'];
export const headerFilterClubWithlistPaths = [
  '/trouver-un-club-de-rugby/resultats-de-recherche',
  '/trouver-un-club-de-rugby/resultats-de-recherche/'
];
export const headerFilterCentenaireWithlistPaths = [
  '/histoires-de-rugby/resultats-de-recherche',
  '/histoires-de-rugby/resultats-de-recherche/',
  '/histoires-de-rugby/carte',
  '/histoires-de-rugby/carte/'
];
export const topBarMenuApiName = 'top-nav';
export const headerMenuApiName = 'header';
export const footerMenuApiName = 'footer';

export const TYPE_NORMAL = 'normal';
export const TYPE_MOBILE = 'mobile';

export const teamSubmenuHeaders = [
  {
    menu_order: 5,
    object_slug: '',
    title: 'Général',
    description: '',
    featured_media: { src: '' },
    texte_cta: '',
    object_id: 0,
    type_name: 'endur'
  },
  {
    menu_order: 5,
    object_slug: 'actualites',
    title: 'Actualités',
    description: '',
    featured_media: { src: '' },
    texte_cta: '',
    object_id: 0,
    type_name: 'endur'
  },
  {
    menu_order: 10,
    object_slug: 'resultats',
    title: 'Résultats',
    description: '',
    featured_media: { src: '' },
    texte_cta: '',
    object_id: 0,
    type_name: 'endur'
  },
  {
    menu_order: 15,
    object_slug: 'competitions',
    title: 'Compétitions',
    description: '',
    featured_media: { src: '' },
    texte_cta: '',
    object_id: 0,
    type_name: 'endur'
  },
  {
    menu_order: 20,
    object_slug: 'agenda',
    title: 'Agenda',
    description: '',
    featured_media: { src: '' },
    texte_cta: '',
    object_id: 0,
    type_name: 'endur'
  },
  {
    menu_order: 25,
    object_slug: 'joueurs',
    title: "L'équipe",
    description: '',
    featured_media: { src: '' },
    texte_cta: '',
    object_id: 0,
    type_name: 'endur'
  },
  {
    menu_order: 30,
    object_slug: 'staff',
    title: 'Staff',
    description: '',
    featured_media: { src: '' },
    texte_cta: '',
    object_id: 0,
    type_name: 'endur'
  }
];
