// @flow

import { takeLatest, all, select } from 'redux-saga/effects';
import TagManager from 'react-gtm-module';
import type { Saga } from 'redux-saga';

import { hitTagsArray } from 'constants/hitTagsConstant';
import type { hitTagsArrayType } from 'constants/hitTagsConstant';

import { POST_ACTION_SUCCESS, GET_HIT_FOR_GTM_SUCCESS } from 'actions/structureActions';
import { FETCH_SETTINGS_SUCCESS } from 'actions/appActions';
import { ADD_ADMIN_DELEGATION } from 'actions/magicLinkActions';

import type { GeneratorType } from 'sagas/root';

export default function() {
  
  return function* hitTagsSaga(): GeneratorType {
    yield all(hitTagsArray.map(hitTag =>
      takeLatest(hitTag, throwHitVirtualPages)
    ));
  };

  function* throwHitVirtualPages(action: hitTagsArrayType): Saga<void> {
    const {structureState: { structure }} = yield select();
    const { pathname } = window.location;
    const structureNom = structure && structure.nom ? structure.nom : 'Comité indéfini';
    try {
      switch (action.type) {
       case FETCH_SETTINGS_SUCCESS:
          if (window.location.pathname === '/') {
            TagManager.dataLayer({
              dataLayer: {
                'event': 'virtualPageView',
                'virtualPageURL' : pathname,
                'virtualPageTitle' : 'Mon Site Comité - Home', // window.location.pathname,
                'structure' : structureNom,
              },
              dataLayerName: 'dataLayer'
            });
          }
          break;
        case GET_HIT_FOR_GTM_SUCCESS: // Step de création du site comité
            TagManager.dataLayer({
              dataLayer: {
                'event': 'virtualPageView',
                'virtualPageURL' : pathname,
                'virtualPageTitle' : 'Mon Site Comité', // window.location.pathname,
                'structure' : structureNom,
              },
              dataLayerName: 'dataLayer'
            });
          break;
        case POST_ACTION_SUCCESS: // Lorsque le site est créé
          TagManager.dataLayer({
            dataLayer: {
              'event': 'eventGA',
              'eventCategory' : 'interface_de_gestion',
              'eventAction' : 'site_cree',
              'club' : structureNom,
            },
            dataLayerName: 'dataLayer'
          });
          break;
        case ADD_ADMIN_DELEGATION:
          TagManager.dataLayer({
            dataLayer: {
              'event': 'eventGA',
              'eventCategory' : 'creation_site',
              'eventAction' : 'ajout_admin',
              'club' : structureNom,
            },
            dataLayerName: 'dataLayer'
          });
          break;
        default:
      }
    } catch (e) {
    }
  }
}
