// @flow
import React, { PureComponent } from 'react';

type Props = {
  title: string,
};

class FooterBottomWithoutSiteMap extends PureComponent<Props> {
  
  render() {
    const { title } = this.props;
    
    return (
      <>
        <div className="footer__corporate">
          <div className="container">
            <div className="row">
              <div className="col col-lg-8">
                <ul className="footer__corporate-links">
                  <li>
                    <a target="_blank" rel="noopener noreferrer" href="https://www.ffr.fr/contact" title={`Contacter ${title} de Rugby`}>Nous contacter</a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.ffr.fr/cgu"
                      title={`Consulter les Conditions générales d’utilisation de ${title} de Rugby`}>
                        Conditions générales d’utilisation
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.ffr.fr/donnees-personnelles"
                      title={`Consulter les informations sur les données personnelles de ${title} de Rugby`}>
                        Données personnelles
                    </a>
                  </li>
                  <li>
                    <a target="_blank" rel="noopener noreferrer" href="https://ffrugby.zendesk.com/hc/fr/sections/360003561620-Plateforme-de-cr%C3%A9ation" title={`Consulter la Foire Aux Questions de ${title} de Rugby`}>FAQ</a>
                  </li>
                </ul>
              </div>
              <div className="col col-lg-4">
                <p className="footer__corporate-legal">
                  ©
                  {` ${(new Date()).getFullYear()} ${title}`}
                </p>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default FooterBottomWithoutSiteMap;
