// @flow
import React, { PureComponent } from 'react';

import { withHelmet } from 'infrastructure/seo/Helmet';
import CookieContainer from 'containers/footer/CookieContainer';
import Header from 'components/header/Header';
import Footer from 'components/footer/Footer';
import ModalContainer from 'containers/fragments/ModalContainer';
import type { FaviconType } from 'types/Settings';
import type { StructureType } from 'types/Structure';

import {
  headerBlacklistWithSlugPaths,
  footerBlacklistWithSlugPaths,
  footerBlacklistPaths
} from 'constants/menuConstants';
import Routes from "../../routes";

type HistoryProps = {
  history: any
};

export type StateProps = {
  acceptCookie: boolean,
  favicons: FaviconType, // eslint-disable-line
  titleLigue: string, // eslint-disable-line
  structureID: number,
  structure: StructureType,
};

export type DispatchProps = {
  fetchSettings: () => void,
  fetchMenu: () => void,
  stockMagicLink: (magicLink: string) => void,
  stockStructureID: (structureID: number) => void,
  fetchStructure: (id: number) => void,
  keycloakConnect() :void,
};

type Props = {
  location: any,
  acceptCookies: () => void,
} & DispatchProps &
  StateProps &
  HistoryProps;

class App extends PureComponent<Props> {
  timeout: TimeoutID;

  interval: IntervalID;

  onInputEvent = (e: FocusEvent) => {
    if (e.type === 'focus') {
      window.inputFocused = true;
    } else if (e.type === 'blur') {
      window.inputFocused = false;
    }
  }

  onInputEvent = (e: FocusEvent) => {
    if (e.type === 'focus') {
      window.inputFocused = true;
    } else if (e.type === 'blur') {
      window.inputFocused = false;
    }
  }

  componentDidMount() {
    const {
      acceptCookie,
      acceptCookies,
      fetchSettings,
      fetchMenu,
      structureID,
      fetchStructure,
      location : {
        search,
        state
      },
      history,
      stockMagicLink,
      stockStructureID,
      keycloakConnect,
    } = this.props;

    if (acceptCookie) {
      acceptCookies();
    }

    const inputs = document.querySelectorAll('input');

    keycloakConnect();

    /**
     * Est -on en mode preview
     */
    inputs.forEach(input => {
      input.setAttribute('data-mounted', '1');
      input.addEventListener('focus', this.onInputEvent, false);
      input.addEventListener('blur', this.onInputEvent, false);
    });

    fetchSettings();
    fetchMenu();

    if (search && search.match('magic')) {
      let structureIDFromQS = search.split('&')[1];
      if(structureIDFromQS) {
        structureIDFromQS = parseInt(structureIDFromQS.split('=')[1], 10);
      }
      let magicLink = search.split('=')[1];
      if (magicLink) {
        // eslint-disable-next-line prefer-destructuring
        magicLink = magicLink.split('&')[0];
        if (structureID === 0 && structureIDFromQS) {
          stockMagicLink(magicLink);
          stockStructureID(structureIDFromQS);
          fetchStructure(structureIDFromQS);
        }
        stockMagicLink(magicLink);
      }
    }
    if (state !== undefined) history.replace({ pathname: '/', state: undefined });
    if (structureID) fetchStructure(structureID);
  }

  componentDidUpdate(prevProps: Props) {
    const {
      location: {
        pathname
      },
      history,
      structureID,
      structure: {
        configuration : {
          step
        }
      },
    } = this.props;

    const { location: { pathname: pathnamePrev }, structureID: prevStructureID } = prevProps;

    if (structureID === 0 && !step && pathname !== '/') {
      history.push('/');
    }

    if (structureID !== prevStructureID && step) {
      let road = '';
      if (step === 1) road = 'demarrer';
      if (step === 2) road = 'contenu';
      if (step === 21) road = 'synchronisation';
      if (step === 3) road = 'personnalisation';
      if (step === 4) road = 'verification';
      if (step === 5) road = 'recapitulatif';
      history.push(`/creation/etape-${step}-${road}`);
    }

    if (prevProps !== this.props) {
      const inputs = document.querySelectorAll('input');
      inputs.forEach(input => {
        const isMounted = input.getAttribute('data-mounted') === '1';
        if (!isMounted) {
          input.setAttribute('data-mounted', '1');
          input.addEventListener('focus', this.onInputEvent, false);
          input.addEventListener('blur', this.onInputEvent, false);
        }
      });
    }

    if (pathnamePrev !== pathname) {
      window.scrollTo(0, 0);
    }
  }

  renderFooter() {
    const { location: { pathname } } = this.props;

    if (footerBlacklistPaths.includes(pathname)) return null;
    else if (footerBlacklistWithSlugPaths.includes(pathname.split('/')[1])) return null;
    return (
      <>
        <Footer />
      </>
    );
  }

  componentDidCatch(error, info) {
    const { history } = this.props;

    if (process.env.NODE_ENV === 'production') {
      history.push('/404');
      window.location.reload();
    }
  }

  renderHeader() {
    const { location: { pathname } } = this.props;

    if (headerBlacklistWithSlugPaths.includes(pathname.split('/')[1])) return null;
    return <Header />;
  }

  componentWillUnmount() {
    const inputs = document.querySelectorAll('input');
    inputs.forEach(input => {
      input.removeEventListener('focus', this.onInputEvent, false);
      input.removeEventListener('blur', this.onInputEvent, false);
    });
  }

  render() {
    const { acceptCookies } = this.props;

    return (
      <>
        <ModalContainer />
        {this.renderHeader()}
        <Routes />
        <CookieContainer acceptCookies={acceptCookies} />
        {this.renderFooter()}
      </>
    );
  }
}

// [SEO] Set default meta tags here !
function renderHelmetDefault(Helmet, pageProps) {
  return (
    <Helmet titleTemplate="%s" defaultTitle={pageProps.titleLigue ? pageProps.titleLigue : window.location.href}>
      <link rel="shortcut icon" href={pageProps.favicons['favicon-32']} />
      <link rel="apple-touch-icon" sizes="180x180" href={pageProps.favicons['favicon-180']} />
      <link rel="icon" type="image/png" sizes="32x32" href={pageProps.favicons['favicon-32']} />
      <link rel="icon" type="image/png" sizes="16x16" href={pageProps.favicons['favicon-16']} />
    </Helmet>
  );
}

export default withHelmet(App)(renderHelmetDefault);
