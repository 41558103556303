// @flow
import type { RawSettingsType, SettingsType } from 'types/Settings';
import { nullSettings } from 'types/Settings';
import { mergeIntoDefault } from 'utils/objectUtils';

export function convertSettingsFromApi(rawSettings: RawSettingsType): SettingsType {
  if (!rawSettings) {
    return nullSettings;
  }

  const {
    ligue,
    title,
    description,
    url,
    logo,
    logo_footer,
    logo_transparent,
    class_ligue,
    contacts,
    favicons,
    liens_rs,
    newsletter,
    cookies,
    actualite_topbar,
    url_cgus,
    url_mentions_legales
  } = rawSettings;

  return mergeIntoDefault(nullSettings, {
    ligue,
    title,
    description,
    url,
    logo,
    logo_footer,
    logo_transparent,
    class_ligue,
    contacts,
    favicons,
    liens_rs: {
      fb_lien: liens_rs.fb_lien ? liens_rs.fb_lien : '',
      instagram_lien: liens_rs.instagram_lien ? liens_rs.instagram_lien : '',
      twitter_lien: liens_rs.twitter_lien ? liens_rs.twitter_lien : '',
      youtube_lien: liens_rs.youtube_lien ? liens_rs.youtube_lien : '',
    },
    newsletter,
    cookies,
    actualite_topbar,
    url_cgus,
    url_mentions_legales
  });
}
 