// @flow 

// ACTION TYPES
export const KEYCLOAK_CONNECT = 'KEYCLOAK_CONNECT';
export const KEYCLOAK_CONNECT_SUCCESS = 'KEYCLOAK_CONNECT_SUCCESS';
export const KEYCLOAK_CONNECT_FAILURE = 'KEYCLOAK_CONNECT_FAILURE';

// TYPE ACTION 
export type KEYCLOAK_CONNECT_ACTION = {
  type: 'KEYCLOAK_CONNECT',
};

export type KEYCLOAK_CONNECT_SUCCESS_ACTION = {
  type: 'KEYCLOAK_CONNECT_SUCCESS',
  payload: {
    keycloakData: any,
  }
};

export type KEYCLOAK_CONNECT_FAILURE_ACTION = {
  type: 'KEYCLOAK_CONNECT_FAILURE',
  payload: {
    error: string,
  }
};

export type Action = 
  | KEYCLOAK_CONNECT_ACTION
  | KEYCLOAK_CONNECT_SUCCESS_ACTION
  | KEYCLOAK_CONNECT_FAILURE_ACTION;

// ACTION CREATORS
export const keycloakConnect = (): KEYCLOAK_CONNECT_ACTION => ({
  type: KEYCLOAK_CONNECT,
});

export const keycloakConnected = (keycloakData: any): KEYCLOAK_CONNECT_SUCCESS_ACTION => {  
  return {
    type: KEYCLOAK_CONNECT_SUCCESS,
    payload: { 
      keycloakData: keycloakData,
    },
  };
};

export const keycloakConnectError = (error: string): KEYCLOAK_CONNECT_FAILURE_ACTION => ({
  type: KEYCLOAK_CONNECT_FAILURE,
  payload: {
    error,
  },
});
