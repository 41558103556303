// @flow
import { connect } from 'react-redux';

import CreationRequestForm from 'components/creationForm/initialStep/CreationRequestForm';
import { askMagicLink } from 'actions/magicLinkActions';
import type { DispatchType } from 'types/Actions';
import type { DispatchProps } from 'components/creationForm/initialStep/CreationRequestForm';

export default connect(
  null,
  (dispatch: DispatchType): DispatchProps => ({
    askMagicLink: (email: string) => dispatch(askMagicLink(email)),
  }),
)(CreationRequestForm);