// @flow
import TopBar from 'components/header/TopBar';
import { connect } from 'react-redux';
import type { StateType } from 'types/Actions';

export default connect((state: StateType) => ({
  topBarElements: state.menuState.topBar,
  tickerElements: state.settingsState.actualite_topbar,
  connectionInProgress: state.userState.connectionInProgress,
  keycloakData: state.userState.keycloakData,
}))(TopBar);
