// npm imports
import * as Keycloak from "keycloak-js";

// local imports
import {
	KEYCLOAK_CONNECT,
	keycloakConnected,
	keycloakConnectError,
} from 'actions/userActions';
import { getInitSsoOptions } from 'constants/sso';

// eslint-disable-next-line
let authenticated = false;
// eslint-disable-next-line
let keycloakData = null;
let keycloak = null;

export default (store) => (next) => (action) => {
	switch (action.type) {
		case KEYCLOAK_CONNECT:
		//* check authetification
		const initSsoOptions = getInitSsoOptions();
		keycloak = Keycloak(initSsoOptions);
			let options = {
				onLoad: 'check-sso',
				silentCheckSsoRedirectUri: '',
			};
			if (window.location.hostname.match('ffr.fr')) {
				options.silentCheckSsoRedirectUri = window.location.origin + '/silent-check-sso.html';
			}

			keycloak.init(options).then(async authenticated => {
				if (authenticated) {
					authenticated = true;

					keycloak.onTokenExpired = () => {
						// first param = minValidity in seconds
						keycloak.updateToken(50).then((refreshed) => {
								// if (refreshed){
								// 	// eslint-disable-next-line
								// 		console.log('refreshed ');
								// } else {
								// 	// eslint-disable-next-line
								// 		console.log('not refreshed ');
								// }

								store.dispatch(keycloakConnected({ keycloak, authenticated }));
						}).error(() => {
								console.error('Failed to refresh token ');
						});
					};
				} else {
					authenticated = false;
				};
				store.dispatch(keycloakConnected({ keycloak, authenticated }));
			}).catch(error => {
				console.error(error);
				store.dispatch(keycloakConnectError(error));
			});
			next(action);
			break;
		default: 
			next(action);
	};
};