// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import type { NewsletterSubscriptionType } from 'types/NewsletterSubscription';
import { convertOptionsFromApi } from 'services/Newsletter';

function NewsletterApi(executor: ApiExecutorType) {
  return { fetchOptions, subscribeToNewsletter };

  function fetchOptions(campaignMonitorKey: string): Promise<NewsletterSubscriptionType> {
    return executor
      .get(`/ffr/v1/campaign_monitor?fields`)
      .then(response => convertOptionsFromApi(response[0]));
  }

  function subscribeToNewsletter(
    entry: any,
    campaignMonitorKey: string,
    typeNewsletter: string | boolean
    ): Promise<any> {
    let route = `/ffr/v1/campaign_monitor`;
    if (typeNewsletter === 'salesforce') {
      route  = `/ffr/v1/salesforce_newsletter`;
    }
    return executor.post(route, entry, {
      headers: { 'Content-Type': "application/x-www-form-urlencoded" }
    }).then(response => {
      if(response.Code) return { error: true, ...response };
      else return response;
    });
  }
}

export default NewsletterApi;
