// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import type { MenuElementType } from 'types/MenuElement';
import { getPublicAssets } from 'utils/assetsUtils';
import type { ParticipateType } from 'types/Participate';

import { convertMenusFromApi } from 'services/Menu';

function MenuApi(executor: ApiExecutorType) {
  return { fetchMenu };

  function fetchMenu(): Promise<{
    topBar: Array<MenuElementType>,
    header: Array<MenuElementType>,
    footer: Array<MenuElementType>,
    participate: Array<ParticipateType>
  }> {
    return Promise.all([executor.get('/ffr/v1/nav_menus_hierarchical')
      .then(convertMenusFromApi), fetchParticipateHub()])
      .then(
        response => ({ ...response[0], ...response[1] })
      );
  }

  function fetchParticipateHub(): Promise<{ participate: Array<ParticipateType> }> {
    return new Promise(resolve =>
      setTimeout(
        () =>
          resolve({
            participate: [
              {
                img: getPublicAssets('img/trash/jeu.jpg'),
                title: 'Jouer au rugby',
                description: 'Tout savoir sur les offres du rugby près de chez vous',
                label: 'Devenir jouer de rugby'
              },

              {
                img: getPublicAssets('img/trash/arbitre.jpg'),
                title: 'Arbitrer',
                description: "Comment devenir arbitre et l'organisation de l'arbitrage en Bretagne",
                label: 'Devenir arbitre'
              },
              {
                img: getPublicAssets('img/trash/entraineur.jpg'),
                title: 'Dévenir éducateur',
                description: 'Les formations pour éducateur et les bonnes pratiques.',
                label: 'Découvrir les formations'
              },
              {
                img: getPublicAssets('img/trash/benevole.jpg'),
                title: 'Devenir bénévole',
                description: 'Participer à la vie du rugby de votre région',
                label: 'Proposer son aide'
              }
            ]
          }),
        1000
      )
    );
  }
}

export default MenuApi;
