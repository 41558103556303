// @flow
import React, { PureComponent } from 'react';
import { Link, withRouter } from 'react-router-dom';

import MenuElement from 'components/header/MenuElement';
import MenuElementPlus from 'components/header/MenuElementPlus';

import { logoWidth } from 'constants/menuConstants';
import { getConfSso } from 'constants/sso';

import type { liensRsType } from 'types/Settings';
import type { MenuElementType } from 'types/MenuElement';
import type { RouterProps } from 'types/Router';

import userAvatar from './placeholder-profil.png';

type StateProps = {
  menuElements: MenuElementType[],
  logo: string,
  logo_transparent: string,
  titleLigue: string,
  liens_rs: liensRsType,
  magicLink: string,
  email: string,
  connectionInProgress: boolean,
  keycloakData: any,
};

type DispatchProps = {
  displayModal: (modalNb: number, content: any) => void,
};

type Props = {
  onCloseMenu: Function,
  location: any,
} & StateProps &
  DispatchProps &
  RouterProps;

type ComponentState = {
  isMobile: boolean,
  hiddenMenuItems: Array<number>,
  menuLinkX: number,
  hiddenSubMenu: boolean,
};

class Menu extends PureComponent<Props, ComponentState> {
  _menuElements: Array<?MenuElement>;

  _menu: ?HTMLElement;

  _menuRight: ?HTMLElement;

  _menuLink: ?HTMLElement;

  _frameId: string;

  state: ComponentState = {
    isMobile: false,
    hiddenMenuItems: [],
    menuLinkX: 0,
    hiddenSubMenu: false,
  };

  constructor(props: Props) {
    super(props);
    this._menuElements = [];
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener('resize', this.updateDimensions.bind(this));
  }

  componentDidUpdate(prevProps, prevState) {
    this.updateDimensions();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this));
  }

  resetMenuElementRef = (c: ?MenuElement) => {
    this._menuElements = [];
    this.addMenuElementRef(c);
  };

  addMenuElementRef = (c: ?MenuElement) => {
    this._menuElements.push(c);
  };

  isDisplayed = (id: number) => {
    const { hiddenMenuItems } = this.state;

    return !hiddenMenuItems.includes(id);
  };

  hiddenTemporary = () => {
    this.setState({ hiddenSubMenu: true });

    setTimeout(() => {
      this.setState({ hiddenSubMenu: false });
    }, 500);
  };

  updateDimensions() {
    const { menuLinkX: menuLinkXState, isMobile, hiddenMenuItems } = this.state;
    const menu = this._menu;
    const menuRight = this._menuRight;
    const menuLink = this._menuLink;

    if (menu && menuRight && menuLink) {
      const menuLinkBounds = menuLink.getBoundingClientRect();
      const menuLinkX = menuLinkBounds.left;

      if (menuLinkX !== menuLinkXState) {
        this.setState({
          menuLinkX,
        });
      }

      const maxWidth = menu.offsetWidth;
      const width_logo = logoWidth;

      const width_menu_links =
        this._menuElements.reduce((total, item) => (item ? total + item.getSize() : total), 0) +
        this._menuElements.length * 12;

      const width_menu_right = menuRight.offsetWidth;
      const totalWidth = width_menu_links + width_menu_right + width_logo + 50;

      let currentWidth = totalWidth;
      let deleted = [];
      if (maxWidth > 1000) {
        for (let i = this._menuElements.length; i > 0; i--) {
          if (currentWidth > maxWidth - logoWidth) {
            if (this._menuElements[i]) currentWidth -= this._menuElements[i].getSize();
            deleted = [...deleted, i];
          }
        }
        if (isMobile) this.setState({ isMobile: false });
      } else {
        if (!isMobile) this.setState({ isMobile: true });
      }
      if (
        (deleted.length > 0 || hiddenMenuItems.length > 0) &&
        JSON.stringify(deleted) !== JSON.stringify(hiddenMenuItems)
      ) {
        this.setState({ hiddenMenuItems: deleted });
      }
    }
  }

  renderSubMenu = (subMenus: Array<Object>): any => {
    const { hiddenTemporary } = this;

    return subMenus.map((subMenu, index) => {
      if (subMenu.type === 'custom') {
        const subMenus = subMenu.items ? subMenu.items : [];
        if (subMenu.url === '#') {
          if (subMenus.length > 0) {
            const subMenusRender = [];

            if (subMenus.length > 7) {
              let subMenusSlice = subMenus.slice(0, 6);
              subMenusRender.push(subMenusSlice);
              subMenusSlice = subMenus.slice(7, 13);
              subMenusRender.push(subMenusSlice);
            } else {
              subMenusRender.push(subMenus);
            }

            const classe = this;

            return (
              <li key={`${subMenu.slug_complet}-${subMenu.title}`}>
                <span className="menu__category">{subMenu.title}</span>
                {subMenusRender.map((subMenus, i) => (
                  <ul key={`${subMenu.title}--${subMenu.slug_complet}-${subMenu.title}`}>
                    <>{classe.renderSubMenu(subMenus)}</>
                  </ul>
                ))}
              </li>
            );
          } else {
            return (
              <li key={`${subMenu.slug_complet}-${subMenu.title}`}>
                <span className="menu__category">{subMenu.title}</span>
              </li>
            );
          }
        } else {
          return (
            <li key={`${subMenu.slug_complet}-${subMenu.title}`}>
              <Link to={`${subMenu.slug_complet}`} target="_blank" onClick={() => hiddenTemporary()}>
                {subMenu.title}
              </Link>
            </li>
          );
        }
      } else if (subMenu.type === 'post_type') {
        return (
          <li key={subMenu.slug_complet}>
            <Link to={`${subMenu.slug_complet}`} onClick={() => hiddenTemporary()}>
              {subMenu.title}
            </Link>
          </li>
        );
      }
      return null;
    });
  };

  renderChidrenMenuElement = (subMenus: Array<Object>) => {
    const { hiddenSubMenu } = this.state;

    if (!hiddenSubMenu) {
      return <>{this.renderSubMenu(subMenus)}</>;
    }

    return undefined;
  };

  renderMenuElement = () => {
    const {
      menuElements,
      onCloseMenu,
      location: { pathname },
    } = this.props;
    const { isMobile, menuLinkX } = this.state;
    // const menus = menuElements.filter(item => item.parent === 0);
    let indexMenuElements = 0;

    if (!menuElements) return [];
    let renderedMenu = menuElements.map((menu, index) => {
      const subMenus = menu.items ? menu.items : [];
      if (menu.type === 'post_type') {
        if (menu.classes === 'bouton-vert') {
          return null;
        }

        const url = pathname.split('/');

        if (subMenus.length > 0) {
          let col = false;

          subMenus.forEach(subMenu => {
            if (subMenu.items && subMenu.items.length > 0) {
              col = true;
            }
          });

          const menuEl = (
            <MenuElement
              url={`/${menu.object_slug}`}
              urlType={menu.type}
              title={menu.title}
              titleAll={menu.title}
              isMobile={isMobile}
              ref={index === 0 ? this.resetMenuElementRef : this.addMenuElementRef}
              id={indexMenuElements}
              isDisplayed={this.isDisplayed}
              menuX={menuLinkX}
              key={`${menu.object_slug}`}
              col={!!col}
              isActive={url[1] === menu.object_slug}
              onCloseMenu={onCloseMenu}
            >
              {this.renderChidrenMenuElement(subMenus)}
            </MenuElement>
          );

          indexMenuElements++;
          return menuEl;
        } else {
          const menuEl = (
            <MenuElement
              url={`/${menu.object_slug}`}
              urlType={menu.type}
              title={menu.title}
              titleAll={menu.title}
              isMobile={isMobile}
              ref={index === 0 ? this.resetMenuElementRef : this.addMenuElementRef}
              id={indexMenuElements}
              isDisplayed={this.isDisplayed}
              menuX={menuLinkX}
              key={`${menu.object_slug}`}
              isActive={url[1] === menu.object_slug}
              onCloseMenu={onCloseMenu}
            />
          );
          indexMenuElements++;
          return menuEl;
        }
      }
      return null;
    });

    return renderedMenu;
  };

  openCreationRequestModal = () => {
    const { displayModal, history, magicLink, email } = this.props;
    if (magicLink) {
      history.push('/creation/initialisation');
    } else {
      email ? displayModal(2, { title: `Continuer le site pour mon comité` }) : displayModal(6);
    }
  };

  handleLoggout = event => {
    const { displayModal } = this.props;
    displayModal(11);
  };

  render() {
    const { hiddenMenuItems, isMobile, menuLinkX } = this.state;
    const {
      onCloseMenu,
      logo,
      logo_transparent,
      titleLigue,
      menuElements,
      liens_rs,
      location: { pathname },
      magicLink,
      email,
      connectionInProgress,
      keycloakData: { authenticated, keycloak },
    } = this.props;

    const confSso = getConfSso();
    const keycloakLogo =
      !!keycloak && keycloak.tokenParsed && keycloak.tokenParsed.logo ? keycloak.tokenParsed.logo : '';
    const given_name = !!keycloak && keycloak.tokenParsed ? keycloak.tokenParsed.given_name : '';
    const family_name = !!keycloak && keycloak.tokenParsed ? keycloak.tokenParsed.family_name : '';

    const fromCreationRoute = pathname.includes('creation');
    const hiddenMenuElements = hiddenMenuItems.map(id => {
      return this._menuElements[id];
    });
    let logo_used = logo;
    const header_over =
      !pathname.match('actualites/.*/.*') &&
      !pathname.match('resultats-de-recherche/') &&
      !pathname.match('clubs/') &&
      !pathname.match(/evenements\/[a-z]/g);
    if (header_over) {
      logo_used = logo_transparent;
    }
    if (hiddenMenuElements.length > 0) {
      hiddenMenuElements.shift();
      const menuPlus = menuElements.find(menu => menu.type === 'custom' && menu.title === 'Plus');
      if (menuPlus) {
        const menuPlusChildren = menuElements.filter(item => item.parent === menuPlus.id);
        menuPlusChildren.forEach((menu, index) => {
          hiddenMenuElements.splice(
            0,
            0,
            <MenuElement
              url={`/${menu.object_slug}`}
              urlType={menu.type}
              title={menu.title}
              titleAll={menu.title}
              isMobile={isMobile}
              id={menu.id}
              isDisplayed={this.isDisplayed}
              menuX={menuLinkX}
              key={`${menu.object_slug}`}
              onCloseMenu={onCloseMenu}
            />,
          );
        });
      }
    }

    return (
      <nav className="menu" ref={c => (this._menu = c)}>
        <Link to={{ pathname: '/', state: { fromCreationRoute } }} title="Retour à la page d'accueil">
          <span style={{ position: 'absolute', top: '25%', width: 100, height: 58, display: 'inline-block' }}>
            {logo_used && <img alt="logo" className="menu__logo" src={logo_used} />}
          </span>
        </Link>

        {!connectionInProgress && !authenticated ? (
          <a
            to="/"
            className="btn btn--primary menu__login"
            title="Se connecter ou s'inscrire sur le site"
            href={keycloak && keycloak.createLoginUrl({ redirectUri: confSso.urlEspacePrive })}
          >
            Se connecter / S&apos;inscrire à mon espace perso
          </a>
        ) : (
          !connectionInProgress && (
            <div className="menu__user">
              <p className="ft-truncat">
                {keycloakLogo && <img src={keycloakLogo} alt="" style={{ width: '24px', height: '24px' }} />}
                {!keycloakLogo && <img src={userAvatar} alt="" style={{ width: '24px', height: '24px' }} />}
                {`${given_name ?? ''} ${family_name ?? ''}`}
              </p>
              <ul>
                <li>
                  <a href={confSso.urlEspacePrive} onClick={onCloseMenu}>
                    <i className="icon icon-account is-inline"></i>
                    Espace personnel
                  </a>
                </li>
                <li>
                  <a href={keycloak.createLogoutUrl()}>
                    <i className="icon icon-logout is-inline"></i>
                    Se déconnecter
                  </a>
                </li>
              </ul>
            </div>
          )
        )}

        <ul className="menu__link" ref={c => (this._menuLink = c)}>
          <MenuElementPlus
            hiddenMenuElements={hiddenMenuElements}
            isMobile={isMobile}
            id={10}
            isDisplayed={this.isDisplayed}
            menuX={menuLinkX}
            text="Plus"
          />
        </ul>
        <div className="menu__social">
          {liens_rs.fb_lien && (
            <a
              className="link-icon"
              href={liens_rs.fb_lien}
              target="_blank"
              rel="noopener noreferrer"
              title={`Se rendre sur la page Facebook de ${titleLigue} (nouvel onglet)`}
            >
              <i className="icon icon-facebook" />
            </a>
          )}
          {liens_rs.twitter_lien && (
            <a
              className="link-icon"
              href={liens_rs.twitter_lien}
              target="_blank"
              rel="noopener noreferrer"
              title={`Se rendre sur la page Twitter de ${titleLigue} (nouvel onglet)`}
            >
              <i className="icon icon-twitter" />
            </a>
          )}
          {liens_rs.instagram_lien && (
            <a
              className="link-icon"
              href={liens_rs.instagram_lien}
              target="_blank"
              rel="noopener noreferrer"
              title={`Se rendre sur la page Instagram de ${titleLigue} (nouvel onglet)`}
            >
              <i className="icon icon-instagram" />
            </a>
          )}
          {liens_rs.youtube_lien && (
            <a
              className="link-icon"
              href={liens_rs.youtube_lien}
              target="_blank"
              rel="noopener noreferrer"
              title={`Se rendre sur la page Youtube de ${titleLigue} (nouvel onglet)`}
            >
              <i className="icon icon-youtube" />
            </a>
          )}
        </div>
        <div className="menu__right" ref={c => (this._menuRight = c)}>
          <ul className="menu__link menu__link--only">
            {magicLink && magicLink !== '' && (
              <li>
                <a // eslint-disable-line
                  role="button"
                  tabIndex={0}
                  onClick={this.handleLoggout}
                >
                  Continuer plus tard
                </a>
              </li>
            )}
          </ul>
          {(!magicLink || magicLink === '') && (
            <button type="button" className="btn btn--primary menu__club" onClick={this.openCreationRequestModal}>
              {email ? 'Continuer le site pour mon comité' : 'Créer un site pour mon comité'}
            </button>
          )}
        </div>
        <i
          className="icon icon-close menu__close js-showMenu"
          role="button"
          tabIndex={0}
          onClick={onCloseMenu}
          aria-label="Fermer"
        />
      </nav>
    );
  }
}

export default withRouter(Menu);
