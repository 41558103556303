// @flow

import {
  APP_SET_SHOW_COOKIE_INFO,
  APP_SET_ACCEPT_COOKIE,
  APP_USER_SUBSCRIBE_NEWSLETTER,
} from 'actions/appActions';

import type { ActionType } from 'types/Actions';

export type State = {
  acceptCookie: boolean,
  showCookieInfo: boolean,
  newsletterSubscribed: boolean,
  newsletterPopInCount: number,
};

const initialState: State = {
  acceptCookie: false,
  showCookieInfo: true,
  newsletterSubscribed: false,
  newsletterPopInCount: 0,
};

export default function(state: State = initialState, action: ActionType): State {
  switch (action.type) {
    case APP_SET_ACCEPT_COOKIE:
      return {
        ...state,
        acceptCookie: action.payload.acceptCookie,
        acceptCookieDate: new Date()
      };
    case APP_SET_SHOW_COOKIE_INFO:
      return {
        ...state,
        showCookieInfo: action.payload.showCookieInfo
      };
    case APP_USER_SUBSCRIBE_NEWSLETTER:
      return {
        ...state,
        newsletterSubscribed: action.payload.newsletterSubscribed,
      };
    default:
      return state;
  }
}
