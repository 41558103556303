// @flow

export type ContactFormType = {
  id: number,
  slug: string,
  title: string,
  locale: string
};

export type FormDataType = {
  'contact-structure': string,
  'contact-email': string,
  'contact-message': string,
};

export const nullContactForm: ContactFormType = {
  id: 0,
  slug: '',
  title: '',
  locale: '',
};
