// @flow

export type ListHomeCardType = {
  menu_order?: number,
  object_id: number,
  type_name: string,
  object_slug: string,
  parent_slug: string,
  title: string,
  description: string,
  featured_image: Object,
  texte_cta: string
};

export type BreadcrumbType = {
  name: string,
  to: string,
};

export type HomeCardType = {
  menu_order?: number,
  object_id: number,
  type_name: string,
  object_slug: string,
  parent_slugs: Object,
  title: string,
  description: string,
  featured_image: Object,
  texte_cta: string
};

export type BlocMainPratique = {
  object_id: number,
  type_name: string,
  slug_complet: string,
  title: string,
  description: string,
  featured_image: Object,
  texte_cta: string
};

export type BlocPratique = {
  object_id: number,
  type_name: string,
  slug_complet: string
};

export type EntrepriseBreadcrumb = {
  slug: string,
  title: string,
};

export type BlocFaq = {
  object_id: number,
  type_name: string,
  object_slug: string,
  title: string,
  description: string,
  featured_image: Object,
  parent_slugs: Array<string>,
  texte_cta: string
};

export type ClubSemaine = {
  title: string,
  slug_complet: string,
  embleme: string
};

export type HomePratiques = {
  titre_liste: string,
  bloc_principal: BlocMainPratique,
  pratiques: {
    pratique_5: BlocPratique,
    pratique_7: BlocPratique,
    pratique_10: BlocPratique,
    pratique_15: BlocPratique,
  }
}

export type FaviconType = {
  'android-192': string,
  'android-256': string,
  'apple-touch-icon-152': string,
  'apple-touch-icon-180': string,
  'favicon-16': string,
  'favicon-32': string,
  'mstile-150': string
};

export type liensRsType = {
  fb_lien: string,
  instagram_lien: string,
  twitter_lien: string,
  youtube_lien: string
};

export type SettingsType = {
  ligue: string,
  title: string,
  description: string,
  breadcrumb_offres_entreprise: Array<EntrepriseBreadcrumb>,
  url: string,
  logo: string,
  logo_footer: string,
  logo_transparent: string,
  cat_calendrier_equipes: Array<string>,
  saison_en_cours: number,
  liste_cards: Array<ListHomeCardType>,
  class_ligue: string,
  titre_liste_cards: string,
  gerer_club: HomeCardType,
  bulletin_officiel: HomeCardType,
  trouver_pratiques: HomePratiques,
  accreditations: Array<{ label: string, value: string }>,
  clubs_semaine: Array<ClubSemaine>,
  bloc_entreprise: {
    object_id: number,
    type_name: string,
    title: string,
    description: string,
    texte_cta: string,
    featured_image: string,
    featured_media: Object,
    slug_complet: string,
  },
  bloc_trouver_club: {
    featured_image: string,
    featured_media: Object,
  },
  blocs_faq: Array<BlocFaq>,
  blocs_trouver_club: Array<BlocFaq>,
  contacts: {
    adresse: string,
    adresse_map: string,
    telephone: string,
    email: string,
    horaires: string
  },
  favicons: FaviconType,
  liens_rs: liensRsType,
  newsletter: { type: false | 'campaign_monitor' | 'lien' | 'salesforce', lien?: string, key?: string },
  cookies: number,
  actualite_topbar: {
    link: string,
    title: string,
    label: string
  },
  domaine_multisite: string,
  pratiques_clubs: Array<string>,
  url_cgus: string,
  url_mentions_legales: string
};

export type RawSettingsType = {
  ligue: string,
  title: string,
  description: string,
  url: string,
  email: string,
  breadcrumb_offres_entreprise: Array<EntrepriseBreadcrumb>,
  timezone: string,
  date_format: string,
  time_format: string,
  start_of_week: number,
  language: string,
  use_smilies: boolean,
  default_category: number,
  default_post_format: string,
  posts_per_page: number,
  default_ping_status: string,
  default_comment_status: string,
  logo: string,
  logo_footer: string,
  logo_transparent: string,
  cat_calendrier_equipes: Array<string>,
  saison_en_cours: number,
  class_ligue: string,
  titre_liste_cards: string,
  liste_cards: Array<HomeCardType>,
  'gerer-club': HomeCardType,
  'bulletin-officiel': HomeCardType,
  liste_blocs_un: {
    gros_bloc: HomeCardType,
    petit_bloc: HomeCardType
  },
  blocs_faq: Array<BlocFaq>,
  blocs_trouver_club: Array<BlocFaq>,
  bloc_trouver_club: {
    featured_image: string,
    featured_media: Object,
  },
  trouver_pratiques: HomePratiques,
  accreditations: Array<{ label: string, value: string }>,
  clubs_semaine: Array<ClubSemaine>,
  bloc_entreprise: {
    object_id: number,
    type_name: string,
    title: string,
    description: string,
    texte_cta: string,
    featured_image: string,
    featured_media: Object,
    slug_complet: string,
  },
  contacts: {
    adresse: string,
    adresse_map: string,
    telephone: string,
    email: string,
    horaires: string
  },
  favicons: FaviconType,
  liens_rs: liensRsType,
  newsletter: { type: false | 'campaign_monitor' | 'lien' | 'salesforce', lien?: string, key?: string },
  cookies: number,
  actualite_topbar: {
    link: string,
    title: string,
    label: string
  },
  domaine_multisite: string,
  pratiques: Array<string>,
  url_cgus: string,
  url_mentions_legales: string
};

export const nullSettings: SettingsType = {
  ligue: '',
  title: '',
  description: '',
  url: '',
  logo: '',
  logo_footer: '',
  logo_transparent: '',
  breadcrumb_offres_entreprise: [],
  cat_calendrier_equipes: [],
  saison_en_cours: 0,
  liste_cards: [
    {
      menu_order: 0,
      object_id: 0,
      type_name: '',
      object_slug: '',
      parent_slug: '',
      title: '',
      description: '',
      featured_image: {},
      texte_cta: ''
    }
  ],
  class_ligue: '',
  titre_liste_cards: '',
  gerer_club: {
    object_id: 0,
    type_name: '',
    object_slug: '',
    title: '',
    description: '',
    parent_slugs: {},
    featured_image: {},
    texte_cta: ''
  },
  bulletin_officiel: {
    object_id: 0,
    type_name: '',
    object_slug: '',
    title: '',
    description: '',
    parent_slugs: {
      '1': '',
      '2': ''
    },
    featured_image: {},
    texte_cta: ''
  },
  accreditations: [],
  clubs_semaine: [],
  trouver_pratiques: {
    titre_liste: 'Trouver le rugby qui vous correspond',
    bloc_principal: {
      object_id: 0,
      type_name: '',
      slug_complet: '',
      title: '',
      description: '',
      featured_image: {},
      texte_cta: ''
    },
    pratiques: {
      pratique_5: {
        object_id: 0,
        type_name: '',
        slug_complet: ''
      },
      pratique_7: {
        object_id: 0,
        type_name: '',
        slug_complet: ''
      },
      pratique_10: {
        object_id: 0,
        type_name: '',
        slug_complet: ''
      },
      pratique_15: {
        object_id: 0,
        type_name: '',
        slug_complet: ''
      },
    }
  },
  bloc_entreprise: {
    object_id: 0,
    type_name: '',
    title: '',
    description: '',
    texte_cta: '',
    featured_image: '',
    featured_media: {},
    slug_complet: '',
  },
  bloc_trouver_club: {
    featured_image: '',
    featured_media: {},
  },
  blocs_faq: [
    {
      object_id: 0,
      type_name: '',
      object_slug: '',
      title: '',
      description: '',
      featured_image: {},
      parent_slugs: [''],
      texte_cta: ''
    }
  ],
  blocs_trouver_club: [
    {
      object_id: 0,
      type_name: '',
      object_slug: '',
      title: '',
      description: '',
      featured_image: {},
      parent_slugs: [''],
      texte_cta: ''
    }
  ],
  contacts: {
    adresse: '',
    adresse_map: '',
    telephone: '',
    email: '',
    horaires: ''
  },
  favicons: {
    'android-192': '',
    'android-256': '',
    'apple-touch-icon-152': '',
    'apple-touch-icon-180': '',
    'favicon-16': '',
    'favicon-32': '',
    'mstile-150': ''
  },
  liens_rs: {
    fb_lien: '',
    instagram_lien: '',
    twitter_lien: '',
    youtube_lien: ''
  },
  newsletter: { type: false },
  cookies: 0,
  actualite_topbar: {
    link: '',
    title: '',
    label: ''
  },
  pratiques_clubs: [],
  domaine_multisite: '',
  url_cgus: '',
  url_mentions_legales: ''
};
