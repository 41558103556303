// @flow
import React, { PureComponent } from 'react';

import TextInputMaterial from 'components/fragments/input/material/TextInputMaterial';

import { validateEmail } from 'utils/validatorUtils';

import type { StructureType, ConfigType } from 'types/Structure';

export type StateProps = {
  structure: StructureType,
  structureID: number,
};

export type DispatchProps = {
  hideModal: () => void,
  postStructureAction: (structureID: number, config: ConfigType, email: string) => void,
};

type Props = StateProps & DispatchProps;

type State = {
  value: string,
};

class AddAdminForm extends PureComponent<Props, State> {
  state: State = {
    value: '',
  };

  _handleChange = (value: string) => {
    this.setState({ value });
  }

  _handleSubmit = () => {
    const { value } = this.state;
    const { structure: { configuration }, structureID, postStructureAction, hideModal } = this.props;

    if (!configuration.admin.includes(value) && validateEmail(value)) {
      postStructureAction(
        structureID,
        {
          ...configuration,
          admin: [
            ...configuration.admin,
            value,
          ],
        },
        value);
    }

    hideModal();
  }

  render() {
    const { value } = this.state;

    return (
      <>
        <TextInputMaterial
          onChange={this._handleChange}
          id="MsfXjKQICAra"
          label="Adresse email"
          className="mb-3"
          />
        <a // eslint-disable-line
          className="btn btn--primary btn--full"
          onClick={this._handleSubmit}
          role="button"
          disabled={!validateEmail(value)}
          tabIndex={0}
        >
          Envoyer
        </a>
      </>
    );
  }
}

export default AddAdminForm;
