/// @flow
import React, { PureComponent } from 'react';
import { dynamicClassName } from 'utils/dynamicClassName';
import { Link } from 'react-router-dom';

type Props = {
  label: string,
  className?: string,
  url?: string,
  title?: string,
  isDisabled?: boolean,
  isFull?: boolean,
  isSecondary?: boolean,
  onClick?: Function
};

class Button extends PureComponent<Props> {
  static defaultProps = {
    className: '',
    title: '',
    url: '',
    isFull: false,
    isDisabled: false,
    isSecondary: false,
    onClick: () => {},
  };

  render() {
    const { isDisabled, isFull, isSecondary, onClick, url, label, title, className } = this.props;

    const classNames = dynamicClassName('btn');
    !isSecondary && classNames.add('btn--primary');
    isDisabled && classNames.add('is-disabled');
    isFull && classNames.add('btn--full');
    className && classNames.add(className);

    if (url) {
      return (
        <Link
          title={title}
          onClick={onClick}
          to={url}
          className={classNames.build()}>
          {label}
        </Link>
      );
    }
    return (
      <button
        type='button'
        title={title}
        onClick={onClick}
        className={classNames.build()}>
        {label}
      </button>
    );
  }
}

export default Button;
