/// @flow
import React, { PureComponent } from 'react';
import { dynamicClassName } from 'utils/dynamicClassName';
import { Link } from 'react-router-dom';

type Props = {
  label?: string,
  icon?: string,
  counter?: number,
  isRounded?: boolean,
  isKhaki?: boolean,
  isGrey?: boolean,
  isBlue?: boolean,
  isBlueAlt?: boolean,
  isAzure?: boolean,
  isRed?: boolean,
  isSmall?: boolean,
  link?: string,
  noIcon?: boolean
};

class Label extends PureComponent<Props> {
  static defaultProps = {
    label: undefined,
    icon: undefined,
    counter: undefined,
    isRounded: false,
    isKhaki: false,
    isGrey: false,
    isBlue: false,
    isBlueAlt: false,
    isAzure: false,
    isRed: false,
    isSmall: false,
    link: undefined,
    noIcon: false
  };

  render() {
    const {
      isRounded,
      isKhaki,
      isGrey,
      isBlue,
      isBlueAlt,
      isAzure,
      isRed,
      icon,
      isSmall,
      counter,
      link,
      noIcon,
      label,
    } = this.props;

    const classNamesLabel = dynamicClassName('label');
    const classNamesIcon = dynamicClassName('icon');
    let titleSEO;

    !noIcon && icon && !label && classNamesLabel.add('label--icon');
    isRounded && classNamesLabel.add('label--rounded');
    isKhaki && classNamesLabel.add('label--khaki');
    isGrey && classNamesLabel.add('label--grey');
    isBlue && classNamesLabel.add('label--blue');
    isBlueAlt && classNamesLabel.add('label--blue-alt');
    isSmall && classNamesLabel.add('label--small');
    isAzure && classNamesLabel.add('label--azure');
    isRed && classNamesLabel.add('label--red');
    icon && classNamesIcon.add(icon);

    if (!link) {
      return (
        <span className={classNamesLabel.build()}>
          {label && label}
          {icon && <i className={classNamesIcon.build()} />}
          {counter && counter}
        </span>
      );
    }

    if (label) {
      titleSEO = `Voir tous les contenus du label ${label}`;
    } else {
      titleSEO = 'Voir tous les contenus du label';
    }

    return (
      <Link to={link} title={titleSEO} className={classNamesLabel.build()}>
        {label && label}
        {icon && <i className={classNamesIcon.build()} />}
        {counter && counter}
      </Link>
    );
  }
}

export default Label;
