// @flow
import React from 'react';
import Loadable from 'react-loadable';
import { Route, Switch } from 'react-router-dom';
import type { ComponentType } from 'react';

import Loading from 'components/fragments/Loading';
import PrivateRoute from 'containers/app/PrivateRouteContainer';
import CreationPrivateRouteContainer from 'containers/app/CreationPrivateRouteContainer';

const makeAsyncComponent = (componentImport: Function): $ReadOnly<Object> => {
  return Loadable({
    loader: componentImport,
    loading() {
      return <Loading isBlue backgroundColor="#013974" />;
    }
  });
};

const Billboard = makeAsyncComponent(() => import('containers/billboard/BillboardContainer'));
const PageNotFound = makeAsyncComponent(() => import('components/app/PageNotFound'));
const CreationForm = makeAsyncComponent(() => import('containers/creationSteps/CreationFormContainer'));

Billboard.preload();

export function withProps(externalProps: Object) {
  return (WrapperComponent: ComponentType<Object>) => {
    return class HOSWrapperComponent extends React.PureComponent<Object> {
      render() {
        return <WrapperComponent {...this.props} {...externalProps} />;
      }
    };
  };
}

const Routes = () => (
  <Switch>
    <PrivateRoute exact path="/" component={Billboard} />
    <Route path="/404" component={PageNotFound} />
    <CreationPrivateRouteContainer path="/creation" component={CreationForm} />
    <Route component={PageNotFound} />
  </Switch>
);

export default Routes;
