// @flow

import React, { PureComponent } from 'react';

import Button from 'components/fragments/button/Button';


export type DispatchProps = {
  askMagicLink: (email: string) => void,
};

type Props = { email: string } & DispatchProps;

export default class ReAskMagicLink extends PureComponent<Props> {
 
  handleSubmit = () => {
    const { props: { askMagicLink, email } } = this;
    askMagicLink(email);
  }

  render() {
    const { handleSubmit, props: { email } } = this;

    if (!email) {
      return (
        <>
          <div className="circle-icon circle-icon--alert"></div>
          <p className='mb-1 ft-center'>
            Vous pouvez dès à présent faire une nouvelle demande par mail !
          </p>
        </>
      );
    }
    const texte = `Renvoyer le lien magique à cette adresse\u00A0: ${email}`;
    return (
      <>
        <Button
          label={texte}
          onClick={handleSubmit}
          isFull />
      </>
    );
  }
}
