// @flow
import type { ApiExecutorType } from 'types/ApiExecutorType';
import type { StructureType, ConfigType } from 'types/Structure';
import { getOnboardingMagicHeader } from 'utils/requestUtils';

function StructureApi(executor: ApiExecutorType) {
  return { getStructure, postStructureConfig, postStructureAction };

  function getStructure(id: number, magicLink: string): Promise<StructureType> {
    return executor.get(`ffr/v1/onboarding_structure?structureid=${id}`, getOnboardingMagicHeader(magicLink));
  }

  function postStructureConfig(
    structureid: number,
    configuration: ConfigType,
    magicLink: string
  ): Promise<StructureType> {
    return executor.post(
      'ffr/v1/onboarding_structure',
      { structureid, configuration },
      getOnboardingMagicHeader(magicLink)
    );
  }

  function postStructureAction(
    structureid: number,
    configuration: ConfigType,
    action: string,
    email: string,
    magicLink: string
  ): Promise<StructureType> {
    return executor.post('ffr/v1/onboarding_structure', {
      structureid,
      configuration,
      action,
      email
    }, getOnboardingMagicHeader(magicLink));
  }
}

export default StructureApi;
