export function getConfSso(){
  const conf = {
    urlEspacePrive: 'https://pp.website.ffr.nextmap.io/tableau-de-bord'
  };

  if (process.env.NODE_ENV === 'production') {
    let hostname = 'monsitecomite.ffr.nextmap.cloud';
    if (typeof window !== "undefined" && window && window.location && window.location.hostname !== 'localhost') {
      hostname = window.location.hostname;
    }

    const source = hostname.split('.').splice(1).join('.');

    if (source !== 'ffr.nextmap.io' && source !== 'ffr.nextmap.cloud') {
      conf.urlEspacePrive = 'https://www.ffr.fr/tableau-de-bord';
    }
  }
  return conf;
} 

export function getInitSsoOptions() {
  let ssoOptions =  {
    url: "https://connexion.ffr.fr/auth",
    realm: "FFRpp",
    clientId: "ffr_comites_pp",
    silentCheckSsoRedirectUri: `${window.location.origin}/silent-check-sso.html`,
  };

  if (process.env.NODE_ENV === 'production') {
    let hostname = 'monsitecomite.ffr.nextmap.cloud';
    if (typeof window !== "undefined" && window && window.location && window.location.hostname !== 'localhost') {
      hostname = window.location.hostname;
    }

    const source = hostname.split('.').splice(1).join('.');

    if (source !== 'ffr.nextmap.io' && source !== 'ffr.nextmap.cloud') {
      ssoOptions.realm = 'FFR';
      ssoOptions.clientId = 'ffr_comites';
    }
  }

  return ssoOptions;
}