// @flow
import React, { PureComponent } from 'react';

import Label from 'components/fragments/text/Label';
import { getConfSso } from 'constants/sso';
import type { MenuElementType } from 'types/MenuElement';
import { dynamicClassName } from 'utils/dynamicClassName';

import userAvatar from './placeholder-profil.png';
import TopBarDropdown from './TopBarDropdown';

import './TopBarStyle.css';

type StateProps = {
  topBarElements: MenuElementType[],
  tickerElements: {
    link: string,
    title: string,
    label: string,
  },
  connectionInProgress: boolean,
  keycloakData: any,
};

type State = {
  isAccordionOpen: boolean,
};

type Props = {} & StateProps;

class TopBar extends PureComponent<Props, State> {
  state: State = {
    isAccordionOpen: false,
  };

  wrapperRef: any;

  handleAccordionOpen = () => {
    this.setState(prevState => ({ isAccordionOpen: !prevState.isAccordionOpen }));
  };

  setWrapperRef = (node: any) => {
    this.wrapperRef = node;
  };

  renderTopBarElements = () => {
    const { topBarElements } = this.props;
    const menus = topBarElements.filter(item => item.parent === 0);
    return menus.map(menu => {
      if (menu.items && menu.items.length > 0) {
        return <TopBarDropdown key={menu.id} subMenus={menu.items} title={menu.title} />;
      }

      return (
        <li key={menu.id}>
          <a
            href={menu.url}
            title={`Se rendre sur le site officiel "${menu.title}" de la Fédération Française de Rugby (nouvel onglet)`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {menu.title}
          </a>
        </li>
      );
    });
  };

  render() {
    const {
      connectionInProgress,
      keycloakData: { authenticated, keycloak },
      tickerElements,
    } = this.props;
    const { isAccordionOpen } = this.state;

    const confSso = getConfSso();

    const accordionTriggerDynamicClasses = dynamicClassName('accordion-trigger topbar__user');
    const accordionDynamicClasses = dynamicClassName('topbar__sub accordion-panel');
    const topbarDynamicClasses = dynamicClassName('topbar');

    const logo = !!keycloak && keycloak.tokenParsed && keycloak.tokenParsed.logo ? keycloak.tokenParsed.logo : '';
    const given_name = !!keycloak && keycloak.tokenParsed ? keycloak.tokenParsed.given_name : '';
    const family_name = !!keycloak && keycloak.tokenParsed ? keycloak.tokenParsed.family_name : '';

    if (isAccordionOpen) {
      topbarDynamicClasses.add('has-accordion-open');
      accordionDynamicClasses.remove('is-hidden');
      accordionTriggerDynamicClasses.add('is-selected');
    } else {
      topbarDynamicClasses.remove('has-accordion-open');
      accordionDynamicClasses.add('is-hidden');
      accordionTriggerDynamicClasses.remove('is-selected');
    }

    return (
      <nav className="topbar">
        <ul>
          {this.renderTopBarElements()}

          {tickerElements && tickerElements.link && (
            <li className="topbar__news">
              <a href={`https://www.ffr.fr` + tickerElements.link} title={tickerElements.title}>
                <Label isSmall isBlueAlt label={tickerElements.label ? tickerElements.label : 'La fédération'} />
                <span>{tickerElements.title}</span>
              </a>
            </li>
          )}

          {!connectionInProgress && !authenticated ? (
            <li className="topbar__right">
              <a
                className="topbar__login"
                href={keycloak && keycloak.createLoginUrl({ redirectUri: confSso.urlEspacePrive })}
              >
                Connexion / inscription
              </a>
            </li>
          ) : (
            !connectionInProgress && (
              <li className="topbar__right">
                <a
                  onClick={this.handleAccordionOpen}
                  className={accordionTriggerDynamicClasses.build()}
                  ref={this.setWrapperRef}
                  role="button"
                  tabIndex="0"
                >
                  {logo && <img src={logo} alt="" style={{ width: '24px', height: '24px' }} />}
                  {!logo && <img src={userAvatar} alt="" style={{ width: '24px', height: '24px' }} />}
                  {`${given_name ?? ''} ${family_name ?? ''}`}
                </a>
                <ul className={accordionDynamicClasses.build()} id="accordion-user">
                  <li>
                    <a
                      href={confSso.urlEspacePrive}
                      className="accordion-user-not-close"
                      onClick={() => this.setState({ isAccordionOpen: false })}
                    >
                      <i className="icon icon-account is-inline" />
                      Espace personnel
                    </a>
                  </li>
                  <li>
                    <a href={keycloak.createLogoutUrl()} className="accordion-user-not-close">
                      <i className="icon icon-logout is-inline" />
                      Se déconnecter
                    </a>
                  </li>
                </ul>
              </li>
            )
          )}
        </ul>
      </nav>
    );
  }
}

export default TopBar;
