// @flow
import { connect } from 'react-redux';

import AddAdminForm from 'components/creationForm/monitoring/AddAdminForm';

import { postStructureAction } from 'actions/structureActions';
import { hideModal } from 'actions/modalActions';

import type { StateType, DispatchType } from 'types/Actions';
import type { StateProps, DispatchProps } from 'components/creationForm/monitoring/AddAdminForm';
import type { ConfigType } from 'types/Structure';

export default connect(
  (state: StateType): StateProps => ({
    structure: state.structureState.structure,
    structureID: state.magicLinkState.structureID,
  }),
  (dispatch: DispatchType): DispatchProps => ({
    hideModal: () => dispatch(hideModal()),
    postStructureAction: (structureID: number, config: ConfigType, email: string) =>
      dispatch(postStructureAction(structureID, config, 'ajouter_admin', email)),
  })
)(AddAdminForm);
