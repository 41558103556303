// @flow
import React, { PureComponent } from 'react';

type OwnProps = {
  acceptCookies: () => void,
};

export type StateProps = {
  showCookieInfo: boolean,
  title: string,
  cookies: number,
};

export type DispatchProps = {
  closeCookieInfo: () => void,
  setAcceptCookie: (accept: boolean) => void,
};

type Props = OwnProps & StateProps & DispatchProps;

const cookieEndContent =
  ", vous acceptez les CGU ainsi que l'utilisation de cookies et de technologies similaires, y compris celle" +
  ' de partenaires tiers, pour vous proposer des contenus pertinents et des publicités ciblées en fonction' +
  " de vos centres 'intérêts.";

class Cookie extends PureComponent<Props> {
  onAccept = () => {
    const { acceptCookies, closeCookieInfo, setAcceptCookie } = this.props;

    closeCookieInfo();
    setAcceptCookie(true);
    acceptCookies();
  };

  onRefuse = () => {
    const { closeCookieInfo, setAcceptCookie } = this.props;

    closeCookieInfo();
    setAcceptCookie(false);
  };

  render() {
    const { cookies, title, showCookieInfo } = this.props;

    if (!showCookieInfo) {
      return null;
    }

    return (
      <div className="cookie-container">
        <div className="box cookie">
          <p>
            En poursuivant votre navigation sur
            {' '}
            {title}
            {cookieEndContent}

            <br />

            { cookies !== 0 && (
                <a href="https://www.ffr.fr/politique-de-cookies" target="_blank" rel="noopener noreferrer">
                  En savoir plus sur les cookies
                </a>
              )}

            {' '}
            <a href="https://www.ffr.fr/cgu" target="_blank" rel="noopener noreferrer">
              Afficher les CGUs
            </a>
          </p>

          <div className='btn-container'>
            <button type="button" onClick={this.onAccept} className="btn btn--primary btn--full">
              Accepter les cookies
            </button>

            <button type="button" onClick={this.onRefuse} className="btn btn--primary btn--full">
              Refuser
            </button>
          </div>
        </div>

        <button type="button" className="btn cookie-close" onClick={this.onRefuse}>
          Continuer sans accepter &#10142;
        </button>
      </div>
    );
  }
}

export default Cookie;
