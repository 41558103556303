// @flow
import React, { PureComponent } from 'react';

import TextareaInputMaterial from 'components/fragments/input/material/TextareaInputMaterial';

import type { FormDataType, ContactFormType } from 'types/ContactForm';
import type { StructureType } from 'types/Structure';

export type StateProps = {
  structure: StructureType,
  contactForm: ContactFormType[],
};

export type DispatchProps = {
  fetchContactForm: () => void,
  postContactForm: (formData: FormDataType, id: number) => void,
  hideModal: () => void,
};

type Props = StateProps & DispatchProps;

type State = {
  message: string,
};

class SupportContact extends PureComponent<Props, State> {
  state: State = {
    message: '',
  };

  componentDidMount() {
    const { fetchContactForm } = this.props;

    fetchContactForm();
  }

  _handleChange = (message: string) => {
    this.setState({ message });
  }

  _handleSubmit = () => {
    const { message } = this.state;
    const {
      postContactForm,
      structure: { nom, email },
      contactForm,
      hideModal,
    } = this.props;

    const formData = {
      'contact-structure': nom,
      'contact-email': email,
      'contact-message': message,
    };

    const aideOnBoardingForm = contactForm.filter(form => form.title === 'aide-onboarding')[0];
    const id = aideOnBoardingForm ? aideOnBoardingForm.id : 0;

    postContactForm(formData, id);
    hideModal();
  }

  render() {
    const { message } = this.state;

    return (
      <>
        <TextareaInputMaterial
          onChange={this._handleChange}
          id="MsfXjKQICAra"
          label="Votre demande"
          className="mb-3"
          />
        <a // eslint-disable-line
          className="btn btn--primary btn--full"
          onClick={this._handleSubmit}
          role="button"
          disabled={message === ''}
          tabIndex={0}
        >
          Envoyer
        </a>
      </>
    );
  }
}

export default SupportContact;
