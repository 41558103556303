// @flow
import { connect } from 'react-redux';

import ReAskMagicLink from 'components/creationForm/reAskMagicLink/ReAskMagicLink';

import { askMagicLink } from 'actions/magicLinkActions';

import type { StateType, DispatchType }  from 'types/Actions';
import type { DispatchProps } from 'components/creationForm/reAskMagicLink/ReAskMagicLink';

export default connect(
  (state: StateType) => ({
    email: state.magicLinkState.email,
  }), 
  (dispatch: DispatchType): DispatchProps => ({
    askMagicLink: (email: string) => dispatch(askMagicLink(email)),
  }),
)(ReAskMagicLink);
