// @flow
import { combineReducers } from 'redux';
import appReducer from 'reducers/appReducer';
import menuReducer from 'reducers/menuReducer';
import settingsReducer from 'reducers/settingsReducer';
import modalReducer from 'reducers/modalReducer';
import structureReducer from 'reducers/structureReducer';
import magicLinkReducer from 'reducers/magicLinkReducer';
import contactFormReducer from 'reducers/contactFormReducer';
import newsletterSubscriptionReducer from 'reducers/newsletterSubscriptionReducer';
import userState from 'reducers/userReducer';

export default combineReducers({
  appState: appReducer,
  menuState: menuReducer,
  settingsState: settingsReducer,
  modalState: modalReducer,
  structureState: structureReducer,
  magicLinkState: magicLinkReducer,
  contactFormState: contactFormReducer,
  newsletterSubscriptionState: newsletterSubscriptionReducer,
  userState: userState,
});
