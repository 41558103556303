// @flow
import React from 'react';
import { getPublicAssets } from 'utils/assetsUtils';

type Props = {
  isWhite?: boolean,
  isBlue?: boolean,
  isDarkBlue?: boolean,
  backgroundColor?: string,
  style?: Object,
};

const Loading = (props: Props) => {
  const { backgroundColor, isWhite, isDarkBlue, isBlue, style: styleProps } = props;

  let link;
  const style = {
    height:'500px',
    display:'flex',
    backgroundColor: (props && backgroundColor) ? backgroundColor : 'transparent'
  };
  if (props && isWhite) {
    link = '/img/FFR-Loader-White_2019.gif';
  } else if (props && isBlue) {
    link = '/img/FFR-Loader-Blue_2019.gif';
  } else if (props && isDarkBlue) {
    link = '/img/FFR-Loader-Dark-Blue_2019.gif';
  } else {
    link = '/img/FFR-Loader-LightBlue_2019.gif';
  }

  return (
    <div style={props && styleProps ? style : style}>
      <img src={getPublicAssets(link)} alt="Chargement ..." style={{margin:'auto'}} />
    </div>
  );
};

Loading.defaultProps = {
  isWhite: false,
  isBlue: false,
  isDarkBlue: false,
  backgroundColor: undefined,
  style: undefined,
};

export default Loading;
