// @flow
import HeaderMobile from 'components/header/HeaderMobile';
import { connect } from 'react-redux';

import type { StateType } from 'types/Actions';

export default connect((state: StateType, ...ownProps: any) => ({
  logo: state.settingsState.logo,
  logoOver: state.settingsState.logo_transparent
}))(HeaderMobile);
