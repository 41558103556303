import React, { PureComponent } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import AppContainer from 'containers/app/AppContainer';
import { store, persistor } from "../../store";

class AppProvider extends PureComponent {
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <AppContainer {...this.props} />
          </Router>
        </PersistGate>
      </Provider>
    );
  }
}

export default AppProvider;
